import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";

const CustomerPostSanctionDocs = (props) => {
  var BASE_URL = `${endpoints[environment].api_base}/files/download/`;
  const applId = props.urlparams.params.id;
  const [editdata, setEditdata] = useState(null);
  const [accordionView, setAccordionView] = useState(false);
  const [checkValue, setCheckValue] = useState("COMMUNICATION");
  const [activeAccordion, setActiveAccordion] = useState("permanent_address");
  const [sameFields, setSameFields] = useState([
    {
      name: "residenceStatus",
      label: "Residence Status",
      type: "select",
      options: _enum.RESIDENCE_TYPES,
      readOnly: true,
    },
    {
      name: "pincode",
      label: "PIN code",
      type: "number",
      placeholder: "Please enter",
      maxlength: 6,
      readOnly: true,
    },
    {
      name: "state",
      label: "State",
      type: "select",
      type: "text",
      placeholder: "Please enter",
      readOnly: true,
    },
    {
      name: "city",
      label: "City",
      type: "text",
      placeholder: "Please enter",
      readOnly: true,
    },
    {
      name: "landmark",
      label: "Landmark",
      type: "text",
      placeholder: "Please enter",
      readOnly: true,
    },
    // {
    //   name: "docType",
    //   label: "Document Type",
    //   type: "select",
    //   options: _enum.DOC_TYPES_NEW,
    // },
    // {
    //   name: "fileId",
    //   label: "Document Upload",
    //   fileLabel: "Upload document",
    //   foldername: "postSanctionedDocs",
    //   type: "file",
    //   placeholder: "Please enter",
    // },
    {
      name: "address1",
      label: "Address",
      type: "textarea",
      placeholder: "Please enter",
      readOnly: true,
      // customcolspan: 8,
    },
  ]);
  const [newFields, setNewFields] = useState([
    {
      name: "address1",
      label: "Address 1",
      type: "textarea",
      placeholder: "Please enter",
      customcolspan: 12,
      required: true,
    },
    {
      name: "address2",
      label: "Address 2",
      type: "textarea",
      placeholder: "Please enter",
      customcolspan: 12,
    },
    {
      name: "address3",
      label: "Address 3",
      type: "textarea",
      placeholder: "Please enter",
      customcolspan: 12,
    },
    {
      name: "pincode",
      label: "PIN code",
      type: "number",
      placeholder: "Please enter",
      maxlength: 6,
    },
    {
      name: "state",
      label: "State",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "city",
      label: "City",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "landmark",
      label: "Landmark",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "docType",
      label: "Document Type",
      type: "select",
      options: _enum.DOC_TYPES_NEW,
    },
    {
      name: "fileId",
      label: "Document Upload",
      fileLabel: "Upload document",
      foldername: "postSanctionedDocs",
      type: "file",
      placeholder: "Please enter",
    },
  ]);

  const [referenceFields1, setReferenceFields1] = useState([
    {
      label: "Enter Name",
      name: "reference1name",
      type: "text",
      required: true,
    },
    {
      label: "Mobile No.",
      name: "reference1mobile",
      type: "number",
      required: true,
    },
    {
      label: "Relation",
      name: "reference1relation",
      type: "select",
      options: _enum.RELATIONS_1,
      required: true,
    },
  ]);
  const [referenceFields2, setReferenceFields2] = useState([
    {
      label: "Enter Name",
      name: "reference2name",
      type: "text",
      required: true,
    },
    {
      label: "Mobile No.",
      name: "reference2mobile",
      type: "number",
      required: true,
    },
    {
      label: "Relation",
      name: "reference2relation",
      type: "select",
      options: _enum.RELATIONS_2,
      required: true,
    },
  ]);

  const [bankFields, setBankFields] = useState([
    { label: "Account Holder Name", name: "accountHolderName", type: "text" },
    { label: "Account No.", name: "accountNumber", type: "number" },
    {
      label: "Bank Name",
      name: "accountHolderBankName",
      type: "text",
    },
    {
      label: "IFSC Code",
      name: "accountHolderBankIFSC",
      type: "text",
    },
  ]);

  const [additionalDocsFields, setAdditionalDocsFields] = useState([
    {},
    {},
    {},
  ]);

  const handleAdditionalDocsChange = (args, index) => {
    console.log({ args, index });
    var documentNameInputCol = document.querySelector(
      `#column${index}-fileName`
    );
    var documentNameInput = document.querySelector(
      `#column${index}-fileName [name="additionalDocsFields.${index}.fileName"]`
    );

    // console.log(documentNameInputCol, documentNameInput);
    const hasOthers = args?.some((obj) => obj.key === "OTHERS");
    if (hasOthers) {
      documentNameInputCol.classList.remove("d-none");
      documentNameInput.setAttribute("required", true);
    } else {
      documentNameInputCol.classList.add("d-none");
      documentNameInput.removeAttribute("required");
    }
  };

  const handleCommunicationAddress = (e) => {
    e.persist();
    let value = e.target.value;
    console.log({ value });

    setCheckValue(value);
  };

  const closeView = () => {
    navigate("/app/myapplications");
  };

  const resolveResponse = (msg, activeacc) => {
    swal2.fire({
      title: msg,
      icon: "success",
      iconColor: "#DE3232",
      confirmButtonColor: "#DE3232",
      confirmButtonText: "Close",
      timer: 3000,
    });
    if (activeacc) {
      document.querySelector(`#${activeacc} button.accordion-button`).click();
    }
    getCustomerPostSanctionedApplication();
  };

  const onCommunicationSubmit = (e, data) => {
    let payload = {
      residenceStatus:
        editdata?.same_address[0]?.residenceStatus ||
        editdata?.permanent_address?.residenceStatus,
      addressType: checkValue,
      address1: data?.address1,
      address2: data?.address2,
      address3: data?.address3,
      pincode: data?.pincode,
      state: data?.state,
      city: data?.city,
      landmark: data?.landmark,
      isAadharAddress: checkValue === "COMMUNICATION" ? true : false,
      docType: data?.docType,
      fileId: data?.fileId,
      fileName: data?.fileName,
    };
    console.log({ data, payload });
    props.apis
      .apiAddCustomerPostDocsAddressInfo(payload, {
        applicationId: applId,
      })
      .then(() => {
        resolveResponse(
          "Permanent Address Added Successfully",
          "referenceDetails"
        );
      });
  };

  const referenceSubmit = (e, data) => {
    let payload = {
      ...data,
    };
    console.log({ payload });
    props.apis
      .apiAddCustomerReferenceDetailsInfo(payload, {
        applicationId: applId,
      })
      .then(() => {
        resolveResponse(
          "Reference Details Updated Successfully",
          "bankAccountDetails"
        );
      });
  };

  const bankAccountSubmit = (e, data) => {
    let payload = {
      ...data,
    };
    console.log({ payload });
    props.apis
      .apiAddCustomerAccountInfo(payload, {
        applicationId: applId,
      })
      .then(() => {
        resolveResponse(
          "Bank Account Details Updated Successfully",
          "additionalDocs"
        );
      });
  };

  const additionalDocsSubmit = (e, data) => {
    let parsedPayload = parseInput(data);
    let payload = parsedPayload?.additionalDocsFields;

    let allFileIdsFilled = payload.every((doc) => doc.fileId !== "");
    console.log({ data, payload, allFileIdsFilled });
    if (!allFileIdsFilled) {
      swal2.fire({
        title: "Document Upload is required",
        icon: "warning",
        iconColor: "#DE3232",
        confirmButtonColor: "#DE3232",
        confirmButtonText: "Close",
        timer: 4000,
      });
    } else {
      props.apis
        .apiAddCustomerAdditionalDocsInfo(payload, {
          applicationId: applId,
        })
        .then(() => {
          resolveResponse("Additional Documents Updated Successfully");
        });
    }
  };

  const handleFinalSubmit = () => {
    let urldata = {
      applicationId: applId,
    };
    props.apis.apiCustomerAcceptSanctionedApplication(urldata).then((res) => {
      swal2.fire({
        text: "Application Approved successfully !!",
        icon: "success",
        iconColor: "#FF4D4D",
        timer: 4000,
      });
      setAccordionView(true);
    });
  };

  const updateFields = (fields, data) => {
    console.log({ data });
    if (data !== undefined) {
      let result = fields?.map((field) => {
        if (data[field?.name] === undefined) {
          console.log(data[field?.name]);
          return {
            ...field,
            defaultValue: "",
            value: "",
          };
        } else if (field.type === "file") {
          console.log({ data, field });
          field.defaultValue = data?.fileId !== null ? data.fileId : "";
          field.value = data?.fileId !== null ? data.fileId : "";
          // field.disabled = true;
          return {
            ...field,
          };
        } else if (field.name === "docType") {
          console.log(data, field, data[field?.name]);
          field.defaultValue = data[field?.name];
          field.value = data[field?.name];
          return {
            ...field,
          };
        } else if (field.name === "fileName") {
          return {
            ...field,
            defaultValue: data["fileName"],
          };
        } else {
          return {
            ...field,
            defaultValue: data[field?.name],
          };
        }
      });
      return result;
    }
  };

  useEffect(() => {
    if (editdata) {
      console.log(editdata);
      //------------------------- UPDATE SAME ADDRESS -------------------------
      let updatedAddress = updateFields(sameFields, editdata?.same_address[0]);
      if (updatedAddress) setSameFields([...updatedAddress]);

      //------------------------- UPDATE NEW ADDRESS -------------------------
      let updatedNewAddress = updateFields(
        newFields,
        editdata?.permanent_address[0]
      );
      if (updatedNewAddress) setNewFields([...updatedNewAddress]);

      //------------------------- UPDATE REFERENCE DETAILS -------------------------
      let updatedReferenceDetails1 = updateFields(
        referenceFields1,
        editdata?.reference_details_1
      );
      setReferenceFields1([...updatedReferenceDetails1]);

      let updatedReferenceDetails2 = updateFields(
        referenceFields2,
        editdata?.reference_details_2
      );
      setReferenceFields2([...updatedReferenceDetails2]);

      //------------------------- UPDATE BANK DETAILS -------------------------
      let updatedBankDetails = updateFields(
        bankFields,
        editdata?.bank_account_details
      );
      setBankFields([...updatedBankDetails]);

      //------------------------- UPDATE ADDITIONAL DOCS -------------------------
      let additionalDocs = [];
      if (editdata?.additionalDocsSubmitted?.length > 0) {
        additionalDocs = editdata?.additionalDocsSubmitted;
      } else if (editdata?.additionalDocsRequested?.length > 0) {
        additionalDocs = editdata?.additionalDocsRequested?.map((item) => {
          return { docType: item };
        });
      }
      // console.log({ additionalDocs });
      setAdditionalDocsFields([...additionalDocs]);
    }
  }, [editdata]);

  const getCustomerPostSanctionedApplication = () => {
    let urldata = {
      applicationId: applId,
    };
    props.apis.apiGetCustomerpPostSanctionedApplication(urldata).then((res) => {
      console.log({ res });
      let alteredAddress;
      let addr1 = res?.ApplicationAddressInfo[0]?.address1?.toTitleCase();
      let addr2 = res?.ApplicationAddressInfo[0]?.address2?.toTitleCase();
      let addr3 = res?.ApplicationAddressInfo[0]?.address3?.toTitleCase();
      alteredAddress = `${addr1 ? addr1 + "," : ""} ${
        addr2 ? addr2 + "," : ""
      } ${addr3 ? addr3 + "." : ""}`;
      setEditdata({
        address: alteredAddress,
        same_address: res?.ApplicationAddressInfo.filter(
          (v) => v.addressType === "COMMUNICATION"
        ),
        permanent_address: res?.ApplicationAddressInfo.filter(
          (v) => v.addressType === "PERMANENT"
        ),
        reference_details_1: {
          reference1name: res?.reference1name,
          reference1mobile: res?.reference1mobile,
          reference1relation: res?.reference1relation,
        },
        reference_details_2: {
          reference2name: res?.reference2name,
          reference2mobile: res?.reference2mobile,
          reference2relation: res?.reference2relation,
        },
        bank_account_details: {
          accountHolderName: res?.accountHolderName,
          accountNumber: res?.accountNumber,
          accountHolderBankName: res?.accountHolderBankName,
          accountHolderBankIFSC: res?.accountHolderBankIFSC,
        },
        additionalDocsSubmitted: res?.additionalDocsSubmitted,
        additionalDocsRequested: res?.additionalDocsRequested,
      });
    });
  };

  useEffect(() => {
    getCustomerPostSanctionedApplication();
  }, []);

  const getPageTitle = () => {
    return `My Application | (Application No.)${applId}`;
  };
  props.$setNode({
    props,
    activeAccordion,
  });
  return (
    <div>
      <MainPagetitle
        mainTitle={getPageTitle()}
        back={"Post Sanction Documents"}
        backLink={"/app/myapplications"}
        pageTitle={getPageTitle()}
        parentTitle={"Home"}
        pageIcon={imagepaths.greyUserCheckIconActive}
      />
      {!accordionView ? (
        <div className="psdocs_acc_container p-4">
          <Accordion
            defaultActiveKey={activeAccordion}
            className="psdocs_acc mt-4"
          >
            <Accordion.Item eventKey="permanent_address" id="permanentAddress">
              <Accordion.Header>
                Permanent Address
                <div className="btn_arrow_container">
                  <i class="fa-solid fa-chevron-up btn_arrow up"></i>
                  <i class="fa-solid fa-chevron-down btn_arrow down"></i>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div class="content">
                  <AppForm onSubmit={onCommunicationSubmit}>
                    <div className="checkbox_container mb-4">
                      <div class="form-check me-3">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="COMMUNICATION"
                          name="communicationAddress"
                          id="COMMUNICATION"
                          onChange={(...args) =>
                            handleCommunicationAddress(...args)
                          }
                          checked={checkValue === "COMMUNICATION"}
                        />
                        <label class="form-check-label" for="COMMUNICATION">
                          Same as communication address
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="PERMANENT"
                          name="communicationAddress"
                          id="PERMANENT"
                          onChange={(...args) =>
                            handleCommunicationAddress(...args)
                          }
                          checked={checkValue === "PERMANENT"}
                        />
                        <label class="form-check-label" for="PERMANENT">
                          New Address
                        </label>
                      </div>
                    </div>
                    {checkValue === "COMMUNICATION" &&
                      sameFields?.map((field, i) => {
                        return (
                          <div
                            className={`col-12 col-md-${
                              field?.customcolspan || 4
                            }`}
                            key={i}
                          >
                            <AppInput {...field}></AppInput>
                          </div>
                        );
                      })}
                    {checkValue === "PERMANENT" &&
                      newFields?.map((field, i) => {
                        return (
                          <div
                            className={`col-12 col-md-${
                              field?.customcolspan || 4
                            }`}
                            key={i}
                          >
                            <AppInput {...field}></AppInput>
                          </div>
                        );
                      })}
                    {checkValue === "PERMANENT" && (
                      <div className="nav_container mt-4">
                        <button
                          type="button"
                          className="btn btn-primary-lite me-3"
                          onClick={(...args) => closeView(...args)}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-primary ms-3">
                          Save & Next
                        </button>
                      </div>
                    )}
                  </AppForm>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            {/* REFERENCE DETAILS FORM */}
            <Accordion.Item eventKey="reference_details" id="referenceDetails">
              <Accordion.Header>
                Reference Details
                <div className="btn_arrow_container">
                  <i class="fa-solid fa-chevron-up btn_arrow up"></i>
                  <i class="fa-solid fa-chevron-down btn_arrow down"></i>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div class="content">
                  <AppForm onSubmit={referenceSubmit}>
                    <h3 className="grey_title">Reference 1</h3>
                    {referenceFields1?.map((field, i) => {
                      return (
                        <div
                          className={`col-12 col-md-${
                            field?.customcolspan || 4
                          }`}
                          key={i}
                        >
                          <AppInput {...field}></AppInput>
                        </div>
                      );
                    })}
                    <h3 className="grey_title mt-3">Reference 2</h3>
                    {referenceFields2?.map((field, i) => {
                      return (
                        <div
                          className={`col-12 col-md-${
                            field?.customcolspan || 4
                          }`}
                          key={i}
                        >
                          <AppInput {...field}></AppInput>
                        </div>
                      );
                    })}
                    <div className="nav_container mt-4">
                      <button
                        type="button"
                        className="btn btn-primary-lite me-3"
                        onClick={(...args) => closeView(...args)}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary ms-3">
                        Save & Next
                      </button>
                    </div>
                  </AppForm>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            {/* BANK ACCOUNT DETAILS */}
            <Accordion.Item
              eventKey="bank_account_details"
              id="bankAccountDetails"
            >
              <Accordion.Header>
                Bank Account Details
                <div className="btn_arrow_container">
                  <i class="fa-solid fa-chevron-up btn_arrow up"></i>
                  <i class="fa-solid fa-chevron-down btn_arrow down"></i>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div class="content">
                  <AppForm onSubmit={bankAccountSubmit}>
                    {bankFields?.map((field, i) => {
                      return (
                        <div
                          className={`col-12 col-md-${
                            field?.customcolspan || 4
                          }`}
                          key={i}
                        >
                          <AppInput {...field}></AppInput>
                        </div>
                      );
                    })}
                    <div className="nav_container mt-4">
                      <button
                        type="button"
                        className="btn btn-primary-lite me-3"
                        onClick={(...args) => closeView(...args)}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary ms-3">
                        Save & Next
                      </button>
                    </div>
                  </AppForm>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            {/* ADDITIONAL DOCS */}
            {editdata?.additionalDocsRequested?.length > 0 && (
              <Accordion.Item eventKey="additional_docs" id="additionalDocs">
                <Accordion.Header>
                  Additional Document
                  <div className="btn_arrow_container">
                    <i class="fa-solid fa-chevron-up btn_arrow up"></i>
                    <i class="fa-solid fa-chevron-down btn_arrow down"></i>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div class="content">
                    <AppForm onSubmit={additionalDocsSubmit}>
                      <>
                        {additionalDocsFields?.map((field, i) => {
                          return (
                            <div className="row">
                              <div
                                className="col-12 col-md-4"
                                key={"field" + i}
                                id={`column${i}-docType`}
                              >
                                <AppInput
                                  name={`additionalDocsFields.${i}.docType`}
                                  label="Document Type"
                                  type="select"
                                  onChange={(e) =>
                                    handleAdditionalDocsChange(e, i)
                                  }
                                  options={_enum.DOC_TYPES_NEW_WITH_OTHERS}
                                  required={true}
                                  defaultValue={field.docType}
                                ></AppInput>
                              </div>
                              <div
                                className="col-12 col-md-4 d-none"
                                key={"field" + i}
                                id={`column${i}-fileName`}
                              >
                                <AppInput
                                  name={`additionalDocsFields.${i}.fileName`}
                                  label="Document Name"
                                  type="text"
                                  defaultValue={
                                    field.fileName
                                    //  || field.docType?.toTitleCase()?.toLowerCase()
                                  }
                                ></AppInput>
                              </div>
                              <div
                                className="col-12 col-md-4"
                                key={"field" + i}
                                id={`column${i}-fileId`}
                              >
                                <AppInput
                                  name={`additionalDocsFields.${i}.fileId`}
                                  label="Document Upload"
                                  fileLabel="Upload document"
                                  foldername="postSanctionedDocs"
                                  type="file"
                                  placeholder="Please enter"
                                  required={true}
                                  defaultValue={field.fileId}
                                ></AppInput>
                              </div>
                            </div>
                          );
                        })}
                      </>
                      <div className="nav_container mt-4">
                        <button
                          type="button"
                          className="btn btn-primary-lite me-3"
                          onClick={(...args) => closeView(...args)}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-primary ms-3">
                          Save & Submit
                        </button>
                      </div>
                    </AppForm>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
          <div className="nav_container mt-4">
            <button
              type="button"
              className="btn btn-primary-lite me-3"
              onClick={(...args) => closeView(...args)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary ms-3"
              onClick={(...args) => handleFinalSubmit(...args)}
            >
              Submit
            </button>
          </div>
        </div>
      ) : (
        <div className="p-4">
          <div className="row g-3">
            <div className="col-12 col-lg-4">
              <div className="card customer_acc_view_card">
                <div className="card_header mb-3">
                  <img src={imagepaths.walletBlackIcon} alt="walletBlackIcon" />
                  <h2>Permanent Address</h2>
                </div>
                <div className="card_body">
                  <p className="c_label">Permanent Address: </p>
                  <p className="c_data">{editdata?.address}</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <div className="card customer_acc_view_card">
                <div className="card_header mb-3">
                  <img
                    src={imagepaths.userSearchBlackIcon}
                    alt="userSearchBlackIcon"
                  />
                  <h2>Reference Details</h2>
                </div>
                <div className="card_body">
                  <div className="reference_box_container">
                    <div className="reference_box">
                      <p className="text-primary m-0">Reference 1</p>
                      <ul>
                        <li>
                          Name:
                          <span>
                            {editdata?.reference_details_1?.reference1name}
                          </span>
                        </li>
                        <li>
                          Mobile No.:
                          <span>
                            {editdata?.reference_details_1?.reference1mobile}
                          </span>
                        </li>
                        <li>
                          Relation :
                          <span>
                            {editdata?.reference_details_1?.reference1relation}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="reference_box">
                      <p className="text-primary m-0">Reference 2</p>
                      <ul>
                        <li>
                          Name:
                          <span>
                            {editdata?.reference_details_2?.reference2name}
                          </span>
                        </li>
                        <li>
                          Mobile No.:
                          <span>
                            {editdata?.reference_details_2?.reference2mobile}
                          </span>
                        </li>
                        <li>
                          Relation :
                          <span>
                            {editdata?.reference_details_2?.reference2relation}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="card customer_acc_view_card">
                <div className="card_header mb-3">
                  <img src={imagepaths.walletBlackIcon} alt="walletBlackIcon" />
                  <h2>Bank Account Details</h2>
                </div>
                <div className="card_body">
                  <div className="reference_box_container">
                    <div className="reference_box">
                      <ul>
                        <li>
                          Account Holder Name :
                          <span>
                            {editdata?.bank_account_details?.accountHolderName}
                          </span>
                        </li>
                        <li>
                          Account No. :
                          <span>
                            {editdata?.bank_account_details?.accountNumber}
                          </span>
                        </li>
                        <li>
                          Bank Name :
                          <span>
                            {
                              editdata?.bank_account_details
                                ?.accountHolderBankName
                            }
                          </span>
                        </li>
                        <li>
                          IFSC Code. :
                          <span>
                            {
                              editdata?.bank_account_details
                                ?.accountHolderBankIFSC
                            }
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <div className="card customer_acc_view_card">
                <div className="card_header mb-3">
                  <img src={imagepaths.docsSheildIcon} alt="docsSheildIcon" />
                  <h2>Additional Document</h2>
                </div>
                <div className="card_body">
                  <div className="docs_card_container row g-0">
                    {editdata?.additionalDocsSubmitted?.length > 0 &&
                      editdata?.additionalDocsSubmitted.map((document) => {
                        return (
                          <>
                            <div className="col-6 col-sm-4 col-md-6 col-lg-3">
                              <div className="docs_card">
                                <img
                                  src={imagepaths.docsCardRedIcon}
                                  alt="docsCardRedIcon"
                                />
                                <h2 className="title">{document?.fileName}</h2>
                                <a
                                  href={`${BASE_URL}${document?.fileId}`}
                                  className="text-primary"
                                >
                                  Download
                                </a>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default constore(CustomerPostSanctionDocs);
