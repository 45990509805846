import React, { useState } from "react";
import { Link } from "react-router-dom";
import OpenRouteModel from "../OpenRouteModel";
import swal from "sweetalert";
import moment from "moment";

function Register(props) {
  let errorsObj = {
    customerName: "",
    emailAddress: "",
    pincode: "",
    termsCheck: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [isLoading, setLoading] = useState(false);
  const fields = [
    {
      name: "customerName",
      label: "Full Name (As per PAN Card)",
      type: "text",
      placeholder: "Please enter",
      customcolspan: 12,
      required: true,
    },
    {
      name: "emailAddress",
      label: "Email ID",
      type: "email",
      placeholder: "Please enter",
      customcolspan: 12,
      required: true,
    },
    {
      name: "pincode",
      label: "PIN Code",
      type: "number",
      placeholder: "Please enter",
      customcolspan: 6,
      required: true,
    },
    {
      name: "gender",
      label: "Gender",
      type: "select",
      options: _enum.GENDER,
      placeholder: "Please enter",
      customcolspan: 6,
      required: true,
    },
    {
      name: "dob",
      label: "DOB",
      type: "date",
      onChange: (value) => handleDob(value),
      disabledDate: (current) => {
        const today = moment();
        const maxDate = today.subtract(18, "years");
        return current && current > maxDate.endOf("day");
      },
      defaultValue: moment().subtract(18, "years"),
      placeholder: "Please enter",
      customcolspan: 6,
      required: true,
    },
    {
      name: "maritalStatus",
      label: "Marital Status",
      type: "select",
      options: _enum.MARITAL_STATUS,
      placeholder: "Please enter",
      customcolspan: 6,
      required: true,
    },
    {
      name: "religion",
      label: "Religion",
      type: "select",
      options: _enum.RELIGIONS,
      placeholder: "Please enter",
      customcolspan: 6,
      required: true,
    },
  ];

  const isAtLeast18YearsOld = (dob) => {
    // Parse the user's date of birth
    const birthDate = new Date(dob);

    // Get today's date
    const today = new Date();

    // Calculate the age
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    // Adjust the age if the birth date hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    // Check if the user is at least 18 years old
    return age >= 18;
  };

  const handleDob = (record) => {
    let dob = new Date(record).toISOString();
    if (!isAtLeast18YearsOld(dob)) {
      setErrorMessage({
        name: "dob",
        message: "DOB must be at least 18 years old",
      });
    } else {
      setErrorMessage("");
    }
  };

  function onSignUp(e, data, form) {
    e.preventDefault();
    console.log({ data });
    let error = false;
    const errorObj = { ...errorsObj };

    let termscheckval = document.getElementById("termsCheck").checked;
    console.log({ termscheckval });
    if (!termscheckval) {
      errorObj.termsCheck = "Please check this box if you want to proceed";
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    if (
      data?.dob === undefined ||
      data.dob.length === 0 ||
      data.maritalStatus.length === 0 ||
      data.gender.length === 0
    ) {
      swal2.fire({
        title: "Please fill all required fields",
        icon: "error",
        iconColor: "#FF4D4D",
        showCancelButton: false,
        timer: 3000,
      });
      return;
    }
    let payload = {
      ...Object.except(data, ["pincode", "dob", "termsCheck"]),
      pincode: Number(data?.pincode),
      dob: new Date(data?.dob).toISOString(),
    };

    console.log({ payload });

    props.apis
      .apiCustomerSignUpSubmit(payload)
      .then((res) => {
        console.log({ apiCustomerSignUpSubmit: res });
        // redirect to login page
        swal2
          .fire({
            title:
              "Sign-up Completed successfully, Please login with your registered mobile number",
            icon: "success",
            iconColor: "#FF4D4D",
          })
          .then((result) => {
            if (result.isConfirmed) {
              navigate("/open/customer/login");
            }
          });
        setLoading(false);
      })
      .catch((err) => {
        console.log({ OTPlogin: err });
        const errorObj = { ...errorsObj };
        errorObj.termsCheck = "Sign-up failed";
        setErrors(errorObj);
        setLoading(false);
      });
  }

  const closeModal = () => {
    document.querySelector("#openRouteModel #bsmodalclose").click();
  };

  return (
    <>
      <div className="page-wraper">
        <div className="browse-job login-style3">
          <div
            className="bg-img-fix overflow-hidden"
            style={{ backgroundImage: "url(" + imagepaths.loginBg + ")" }}
          >
            <div className="parent-container row gx-0 registration_page">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="logo_header pt-5 ps-5">
                  <Link to={"/open/customer/login"} className="logo">
                    <img src={imagepaths.loginWhiteBg} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="login-form style-2">
                  <div className="card-body">
                    <nav className="nav nav-tabs border-bottom-0">
                      <div className="tab-content w-100" id="nav-tabContent">
                        <div className="tab-pane active show fade registration_card">
                          {props.errorMessage && (
                            <div className="">{props.errorMessage}</div>
                          )}
                          {props.successMessage && (
                            <div className="">{props.successMessage}</div>
                          )}
                          <h3 className="form-title text-center mb-4">
                            Tell us a bit about yourself
                          </h3>
                          <AppForm onSubmit={onSignUp}>
                            {fields.map((field, i) => {
                              return (
                                <>
                                  <div
                                    className={`col-12 col-md-${
                                      field?.customcolspan || 6
                                    }`}
                                    key={i}
                                  >
                                    <AppInput
                                      {...field}
                                      errorMessage={errorMessage}
                                    ></AppInput>
                                  </div>
                                </>
                              );
                            })}
                            <div className="col-12 col-md-12 mb-3 mt-3">
                              <span className="form-check float-start me-2">
                                <input
                                  type="checkbox"
                                  className="form-check-input mt-0"
                                  id="termsCheck"
                                  name="termsCheck"
                                />
                                <label
                                  className="form-check-label d-unset"
                                  htmlFor="termsCheck"
                                >
                                  I have read and agreed to the
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#openRouteModel"
                                    className="text-primary ms-2"
                                  >
                                    Ayaan Finserve India Pvt. Ltd. T&C & Privacy
                                    Policy.
                                  </a>
                                </label>
                              </span>
                              {errors.termsCheck && (
                                <div className="text-danger">
                                  {errors.termsCheck}
                                </div>
                              )}
                            </div>
                            <div className="nav_container d-flex flex-column">
                              <button
                                type="submit"
                                className="btn btn-primary button-md btn-block mb-3"
                                disabled={isLoading}
                              >
                                {isLoading ? "Loading…" : "Proceed"}
                              </button>
                              <Link
                                to={"/open/customer/login"}
                                className="btn btn-primary-lite button-md btn-block bg-transparent"
                              >
                                Cancel
                              </Link>
                            </div>
                          </AppForm>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OpenRouteModel
        id="openRouteModel"
        className="modal-lg openRouteModel"
        modalSize="default"
        title="Terms of Service and Privacy Policy"
        submitText="Understood"
        modaltype="centeredmodal"
        onClose={() => closeModal()}
        footer={true}
        okText="Understood"
      />
    </>
  );
}

export default constore(Register);
