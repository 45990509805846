import React, { useEffect, useRef, useState } from "react";
import FlexCard from "../Helpers/FlexCard";
import { Link } from "react-router-dom";

const Queries = (props) => {
  var BASE_URL = `${endpoints[environment].api_base}/files/download/`;
  var isReadonly = props.urlparams.params.readonly === "true" ? true : false;
  var tabStatus = props.urlparams.params.status;
  const [queryinfo, setQueryinfo] = useState([]);
  // ----------------------------------------------------------------
  const formRef = useRef(null);

  const [summary, setSummary] = useState([]);

  // ----------------------------------------------------------------

  useEffect(() => {
    getQueries();
  }, []);

  const getQueries = () => {
    props.apis
      .apiUnderWriterGetApplicationQuery({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        const groupedConversations = [];
        res.forEach((conversation) => {
          if (!conversation.responseId) {
            // If conversation has no responseId, treat it as parent
            const parentConversation = {
              ...conversation,
              childrenConv: [],
            };
            // Find children conversations
            const childrenConv = res.filter(
              (c) => c.responseId === conversation.conversationId
            );
            parentConversation.childrenConv = childrenConv;
            // Add parent conversation to groupedConversations
            groupedConversations.push(parentConversation);
          }
        });
        const sortedGroups = Object.values(groupedConversations).sort(
          (a, b) => b.conversationId - a.conversationId
        );

        console.log({ sortedGroups });

        setQueryinfo(sortedGroups);
        prepareSummaryInfo(res);
      });
  };
  const prepareSummaryInfo = (res) => {
    let obj = Object.groupBy(res, (item) => {
      return item.category;
    });
    let personalRes =
      obj["PERSONAL_INFO"]?.find((item) => item.uwDecision === "DECLINE") ||
      obj["PERSONAL_INFO"]?.find((item) => item.uwDecision === "DISCREPANCY");

    let incomeRes =
      obj["INCOME_DTLS"]?.find((item) => item.uwDecision === "DECLINE") ||
      obj["INCOME_DTLS"]?.find((item) => item.uwDecision === "DISCREPANCY");
    let cibilRes =
      obj["CIBIL"]?.find((item) => item.uwDecision === "DECLINE") ||
      obj["CIBIL"]?.find((item) => item.uwDecision === "DISCREPANCY");

    let summary = [
      {
        category: "Personal Info.",
        Status: personalRes?.uwDecision || "Approved",
        Remark: personalRes?.messageText || "-",
        AcceptedCount:
          obj["PERSONAL_INFO"]?.filter(
            (item) =>
              item.uwDecision === "DISCREPANCY" && item.status === "ACCEPT"
          )?.length ?? 0,
        RejectedCount:
          obj["PERSONAL_INFO"]?.filter(
            (item) =>
              item.uwDecision === "DISCREPANCY" && item.status === "REJECT"
          )?.length ?? 0,
        PendingCount:
          obj["PERSONAL_INFO"]?.filter(
            (item) => item.uwDecision === "DISCREPANCY" && item.status === null
          )?.length ?? 0,
      },
      {
        category: "Income & BSA Info.",
        Status: incomeRes?.uwDecision || "Approved",
        Remark: incomeRes?.messageText || "-",
        AcceptedCount:
          obj["INCOME_DTLS"]?.filter(
            (item) =>
              item.uwDecision === "DISCREPANCY" && item.status === "ACCEPT"
          )?.length ?? 0,
        RejectedCount:
          obj["INCOME_DTLS"]?.filter(
            (item) =>
              item.uwDecision === "DISCREPANCY" && item.status === "REJECT"
          )?.length ?? 0,
        PendingCount:
          obj["INCOME_DTLS"]?.filter(
            (item) => item.uwDecision === "DISCREPANCY" && item.status === null
          )?.length ?? 0,
      },
      {
        category: "Cibil",
        Status: cibilRes?.uwDecision || "Approved",
        Remark: cibilRes?.messageText || "-",
        AcceptedCount:
          obj["CIBIL"]?.filter(
            (item) =>
              item.uwDecision === "DISCREPANCY" && item.status === "ACCEPT"
          )?.length ?? 0,
        RejectedCount:
          obj["CIBIL"]?.filter(
            (item) =>
              item.uwDecision === "DISCREPANCY" && item.status === "REJECT"
          )?.length ?? 0,
        PendingCount:
          obj["CIBIL"]?.filter(
            (item) => item.uwDecision === "DISCREPANCY" && item.status === null
          )?.length ?? 0,
      },
    ];
    setSummary(summary);
  };

  const handleResponseAccept = (query, type) => {
    console.log(type);
    props.apis
      .apiUnderWriterAcceptQueryResponse({
        conversationId: query.conversationId,
      })
      .then((res) => {
        swal2.fire({
          title: "Query Accepted",
          icon: "success",
          iconColor: "#DE3232",
        });
        getQueries();
      })
      .catch(({ response }) => {});
  };

  const handleResponseReject = (query, type) => {
    console.log(type);
    props.apis
      .apiUnderWriterRejectQueryResponse({
        conversationId: query.conversationId,
      })
      .then((res) => {
        swal2.fire({
          title: "Query Rejected",
          icon: "success",
          iconColor: "#DE3232",
        });
        getQueries();
      })
      .catch(({ response }) => {});
  };

  const handleDecision = (e, data) => {
    let parsedPayload = parseInput(data);
    // console.log("formData", data, parsedPayload);
    let payload = {
      ...parsedPayload,
      applicationId: parseInt(props.urlparams.params.id),
      category: "QUERY",
    };
    console.log({ payload });
    props.apis
      .apiUnderWriterCreateApplicationQuery(payload)
      .then((res) => {
        swal2.fire({
          title: "Decision Updated successfully.",
          icon: "success",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
        tabStatus === "sanctioned"
          ? props.changeTab(e, "postsanctioneddocs")
          : props.changeTab(e, "finalreview");
      })
      .catch((error) => {});
  };

  const handleUWComment = (data, orginalItem) => {
    let payload = {
      ...Object.only(orginalItem, ["applicationId", "category"]),
      ...data,
      responseId: orginalItem.conversationId,
    };
    props.apis.apiAddCustomerQueryResponse(payload).then((res) => {
      formRef?.current?.form?.current?.reset();
      swal2.fire({
        title: "Comment added Successfully !!",
        icon: "success",
        iconColor: "#FF4D4D",
        timer: 4000,
      });
      getQueries();
    });
  };

  return (
    <>
      <div className="card p-4">
        <h2 className="mb-3 fs-4">Summary Details</h2>
        <div className="row g-4 query_card_container">
          {summary.map((item) => {
            return (
              <div className="col-12 col-md-6">
                <div className="query_card count">
                  <p className="title">{item.category}</p>
                  <div className="contents">
                    <ul>
                      <li>
                        <span>Status</span>
                        <span>{item.Status}</span>
                      </li>
                    </ul>
                    <ul className="mr-2">
                      <li>
                        <span>Accepted</span>
                        <span>{item.AcceptedCount}</span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <span>Rejected</span>
                        <span>{item.RejectedCount}</span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <span>Pending</span>
                        <span>{item.PendingCount}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="card p-4">
        <h2 className="mb-3 fs-4">Queries</h2>
        <div className="row g-4 query_card_container">
          {queryinfo &&
            queryinfo
              ?.filter((item) => item.uwDecision === "DISCREPANCY")
              ?.map((query, i) => {
                return (
                  <div className="col-12 col-md-6" key={i}>
                    <div className="query_card">
                      <p className="title">
                        {_enum.CATEGORY_MAP[query?.category] || "-"}
                      </p>
                      {query?.status === "ACCEPT" && (
                        <i class="fa-solid fa-circle-check checkmark_icon"></i>
                      )}
                      {query?.status === "REJECT" && (
                        <i class="fa-solid fa-circle-xmark status_icon"></i>
                      )}
                      <div className="contents">
                        <ul>
                          <li>
                            <span>Status</span>
                            <span>{query?.uwDecision || "-"}</span>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>Remarks</span>
                            <span>{query?.messageText || "-"}</span>
                          </li>
                        </ul>
                      </div>
                      {query?.childrenConv?.map((child, i) => (
                        <div className="bottom_footer">
                          <hr />
                          <div className="docs_card_container row g-0">
                            <div className="col-12 col-md-6">
                              <span>
                                {child.profileId ? "Your" : "Customer"} Remarks
                              </span>
                              <div className="docs_card">
                                {child.fileId && (
                                  <img
                                    src={imagepaths.docsCardRedIcon}
                                    alt="docsCardRedIcon"
                                  />
                                )}
                                <div className="ms-3">
                                  <h2 className="title">
                                    {child?.messageText || "-"}
                                  </h2>
                                  {child.fileId && (
                                    <a
                                      href={`${BASE_URL}${child?.fileId}`}
                                      className="text-primary"
                                    >
                                      Download
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {!query.status && (
                        <>
                          <AppForm
                            ref={formRef}
                            onSubmit={(e, data) => {
                              e.preventDefault();
                              handleUWComment(data, query);
                            }}
                          >
                            <hr />
                            <AppInput
                              type="textarea"
                              label="Comment"
                              name="messageText"
                              required
                            ></AppInput>
                            <div className="d-flex justify-content-end">
                              <button
                                type="submit"
                                className="btn btn-primary me-3 mb-2"
                              >
                                Send
                              </button>
                            </div>
                            <hr />
                          </AppForm>
                          <div className="nav_container mt-5">
                            <button
                              type="button"
                              className="btn btn-primary me-3"
                              disabled={query?.childrenConv?.length === 0}
                              onClick={(...args) =>
                                handleResponseAccept(query, "accept")
                              }
                            >
                              Accept
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary-lite"
                              disabled={query?.childrenConv?.length === 0}
                              onClick={(...args) =>
                                handleResponseReject(query, "reject")
                              }
                            >
                              Reject
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
        </div>
      </div>

      {isReadonly ? (
        <div className="nav_container mt-5">
          <button
            type="button"
            onClick={(...args) => props.changeTab(...args, "cibil")}
            className="btn btn-primary-lite me-3"
          >
            Back
          </button>
          {tabStatus === "sanctioned" ? (
            <button
              type="button"
              className="btn btn-primary"
              onClick={(...args) =>
                props.changeTab(...args, "postsanctioneddocs")
              }
            >
              Next
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary"
              onClick={(...args) => props.changeTab(...args, "finalreview")}
            >
              Next
            </button>
          )}
        </div>
      ) : !isReadonly && tabStatus === "sanctioned" ? (
        <>
          <div className="nav_container mt-5">
            <button
              type="button"
              onClick={(...args) => props.changeTab(...args, "cibil")}
              className="btn btn-primary-lite me-3"
            >
              Back
            </button>
            {tabStatus === "sanctioned" ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={(...args) =>
                  props.changeTab(...args, "postsanctioneddocs")
                }
              >
                Next
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                onClick={(...args) => props.changeTab(...args, "finalreview")}
              >
                Next
              </button>
            )}
          </div>
        </>
      ) : (
        <div>
          {queryinfo
            .filter((query) => query.category === "QUERY")
            .map((item) => (
              <>
                <div className="row">
                  <AppInput
                    name="uwDecision"
                    label="Underwriter Decision"
                    type="text"
                    defaultValue={item.uwDecision}
                    outerclass="col-12 col-md-3"
                    readOnly="true"
                  ></AppInput>

                  <AppInput
                    name="messageText"
                    label="Remarks"
                    readOnly="true"
                    defaultValue={item.messageText}
                    type="textarea"
                    outerclass="col-12 col-md-9 mb-3"
                  ></AppInput>
                </div>
              </>
            ))}
          <AppForm
            className="mx-auto"
            onSubmit={(...args) => handleDecision(...args)}
          >
            <AppInput
              name="uwDecision"
              label="Underwriter Decision"
              type="select"
              options={_enum.UNDER_WRITER_DECISIONS}
              outerclass="col-12 col-md-3"
              required="true"
            ></AppInput>

            <AppInput
              name="messageText"
              label="Remarks"
              type="textarea"
              outerclass="col-12 col-md-9 mb-3"
            ></AppInput>
            <div className="nav_container mt-5">
              <Link
                to={`/app/loanapplications?activetab=${tabStatus}`}
                className="btn btn-primary-lite me-3"
              >
                Cancel
              </Link>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </AppForm>
        </div>
      )}
    </>
  );
};

export default constore(Queries);
