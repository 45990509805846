export default {
  apiHeartBeat() {
    return this.request(this.api().HEARTBEAT)
      .exec()
      .mapresults((response) => {
        return response;
      })
      .log()
      .get();
  },

  apiLogin(data) {
    return this.request(this.api().LOGIN, data)
      .exec()
      .log()
      .mapresults((response) => {
        return new Promise((resolve, reject) => {
          this.request(
            this.api().GET_USER,
            {},
            {
              authorization: "Bearer " + response.access_token,
            }
          )
            .exec()
            .get()
            .then((userresponse) => {
              // console.log("userresponse", userresponse);
              let retdata = {
                auth: response.access_token,
                logged: {
                  ...Object.except(data, ["password"]),
                  ...response,
                },
                user: {
                  ...userresponse,
                  refresh: response.refresh_token,
                },
              };
              resolve(retdata);
              return retdata;
            });
        });
      })
      .get();
  },

  apiLogout() {
    return this.request(this.api().LOGOUT)
      .exec()
      .mapresults((response) => {
        return response;
      })
      .log()
      .get();
  },

  apiCustomerLogout() {
    return this.request(this.api().CUSTOMER_LOGOUT)
      .exec()
      .mapresults((response) => {
        return response;
      })
      .log()
      .get();
  },

  apiOTPLogin(data) {
    console.log("apiOTPLogin", data);
    return this.request(this.api().OTP_LOGIN)
      .urltransform({
        mobile: data.mobile,
      })
      .exec()
      .log()
      .mapresults((res) => {
        return res.data;
      })
      .get();
  },

  apiPasswordGenerate(data) {
    console.log("apiPasswordGenerate", data);
    return this.request(this.api().GENERATE_TEMPORARY_PASSWORD)
      .urltransform({
        email: data.email,
      })
      .exec()
      .log()
      .mapresults((res) => {
        return res.data;
      })
      .get();
  },

  apiPasswordReset(data) {
    console.log("apiPasswordGenerate", data);
    return (
      this.request(this.api().PASSWORD_RESET, data)
        // .urltransform({
        //   email: data.email
        // })
        .exec()
        .log()
        .mapresults((res) => {
          return res;
        })
        .get()
    );
  },

  apiOTPLoginVerify(data) {
    console.log({ apiOTPLoginVerify: data });
    return this.request(this.api().OTP_LOGIN_VERIFY, data)
      .exec()
      .log()
      .mapresults((response) => {
        return new Promise((resolve, reject) => {
          this.request(
            this.api().GET_USER,
            {},
            {
              authorization: "Bearer " + response.access_token,
            }
          )
            .exec()
            .get()
            .then((userresponse) => {
              console.log("userresponse", userresponse);
              let retdata = {
                auth: response.access_token,
                logged: {
                  ...Object.except(data, ["password"]),
                  ...response.data,
                },
                user: {
                  ...userresponse,
                  refresh: response.refresh_token,
                },
              };
              resolve(retdata);
              return retdata;
            });
        });
      })
      .get();
  },

  apiRefreshToken() {
    let refreshTokenAttemp = localStorage.getItem("refreshTokenAttemp");
    let reduxval = localStorage.getItem("redux");
    let rtoken = JSON.parse(reduxval).user?.refresh;
    refreshTokenAttemp = refreshTokenAttemp || 1;
    localStorage.setItem("refreshTokenAttemp", +refreshTokenAttemp + 1);

    return this.request(this.api().REFRESH_TOKEN)
      .headers({
        authorization: "Bearer " + rtoken,
      })
      .exec()
      .log()
      .mapresults((response) => {
        let { logged = {} } = JSON.parse(reduxval);
        let refreshdata = response;
        app?.props?.store("auth", response.access_token);
        app?.props?.store("logged", { ...logged, ...refreshdata });
        app?.props?.store("loggedIn", new Date().getTime());
        app?.props?.store("user.refresh", response.refresh_token);
        localStorage.setItem("refreshTokenAttemp", 0);
      })
      .get();
  },

  // CUSTOMER SIGN-UP

  apiCustomerSignUpRequest(data) {
    console.log("apiCustomerSignUpRequest", data);
    return this.request(this.api().CUSTOMER_SIGNUP_REQUEST)
      .urltransform({
        mobileNumber: data.mobile,
      })
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },

  apiCustomerOTPLoginVerify(data) {
    return this.request(this.api().CUSTOMER_SIGNUP_VERIFY, data)
      .exec()
      .log()
      .mapresults((response) => {
        let returndata = {
          auth: response.access_token,
          refresh: response.refresh_token,
        };
        return returndata;
      })
      .get();
  },

  apiCustomerSignUpSubmit(data) {
    return this.request(this.api().CUSTOMER_SIGNUP_SUBMIT, data)
      .exec()
      .log()
      .mapresults((response) => {
        return response.data;
      })
      .get();
  },

  apiCustomerSignInRequest(data) {
    console.log("apiCustomerSignInRequest", data);
    return this.request(this.api().CUSTOMER_SIGNIN_REQUEST)
      .urltransform({
        mobileNumber: data.mobileNumber,
      })
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },

  apiCustomerSignInVerify(data) {
    console.log("apiCustomerSignInVerify", data);
    return this.request(this.api().CUSTOMER_SIGNIN_VERIFY, data)
      .exec()
      .log()
      .mapresults((response) => {
        return new Promise((resolve, reject) => {
          this.request(
            this.api().GET_CUSTOMER,
            {},
            {
              authorization: "Bearer " + response.access_token,
            }
          )
            .exec()
            .get()
            .then((userresponse) => {
              console.log("userresponse", userresponse);
              let retdata = {
                auth: response.access_token,
                logged: {
                  ...Object.except(data, ["password"]),
                  ...response,
                },
                user: {
                  ...userresponse,
                  refresh: response.refresh_token,
                },
              };
              // setTimeout(() => {
              //   this.apiRefreshToken();
              // }, retdata.logged.access_expires_in);
              resolve(retdata);
              return retdata;
            });
        });
      })
      .get();
  },

  apiCustomerGenerateEmail(urldata = {}) {
    return this.request(this.api().CUSTOMER_GENERATE_VERIFY_EMAIL)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res.data;
      })
      .get();
  },

  apiCustomerVerifyEmailWithToken(urldata = {}) {
    return this.request(this.api().CUSTOMER_VERIFY_EMAIL_WITH_TOKEN)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res.data;
      })
      .get();
  },

  apiGetCustomerInfo(data) {
    return this.request(this.api().GET_CUSTOMER, data)
      .exec()
      .log()
      .mapresults((response) => {
        return response;
      })
      .get();
  },
  // ----------------------------------------------------------------

  apiCustomerPasswordGenerate(data) {
    // console.log("apiCustomerPasswordGenerate", data);
    return this.request(this.api().CUSTOMER_GENERATE_TEMPORARY_PASSWORD)
      .urltransform({
        email: data.email,
      })
      .exec()
      .log()
      .mapresults((res) => {
        return res.data;
      })
      .get();
  },

  apiCustomerPasswordReset(data) {
    // console.log("apiCustomerPasswordReset", data);
    return this.request(this.api().CUSTOMER_PASSWORD_RESET, data)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },

  // FILE UPLOAD

  apiFileUploader(data, urldata = mixins.params()) {
    return this.request(this.api().FILE_UPLOAD, data)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },
  apiFileDownloader(urldata = mixins.params()) {
    return this.request(this.api().FILE_DOWNLOAD)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },
  apiGetFile(data, urldata = mixins.params()) {
    return this.request(this.api().FILE_DOWNLOAD, data)
      .urltransform({ ...data, ...urldata })
      .geturl();
  },
  apiFileRemover(urldata = mixins.params()) {
    return this.request(this.api().DELETE_FILE)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },

  // -----------------------  CUSTOMER API'S  -----------------------

  apiGetAllRegisteredUsers(data) {
    let payload = {
      ...data,
    };
    return this.request(this.api().GET_ALL_REGISTERED_USERS, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },

  // KYC API's
  apiSaveCustomerIncomeDetails(data) {
    // console.log("apiSaveCustomerIncomeDetails", urldata);
    return this.request(this.api().SAVE_CUSTOMER_INCOME_DETAILS, data)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },

  apiUpdateCustomerIncomeDetails(data) {
    // console.log("apiUploadCustomerDocs", data);
    return this.request(this.api().UPDATE_CUSTOMER_INCOME_DETAILS, data)
      .exec()
      .log()
      .mapresults((res) => {
        return res.data;
      })
      .get();
  },

  apiGetCustomerOverallInfo(urldata = {}) {
    return this.request(this.api().GET_CUSTOMER_OVERALL_INFO)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiGetCustomerPanDetails(data) {
    // console.log("apiGetCustomerPanDetails", urldata);
    return this.request(this.api().GET_CUSTOMER_PAN_DETAILS, data)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },

  apiVerifyCustomerPanDetails(data) {
    console.log("apiVerifyCustomerPanDetails", data);
    return this.request(this.api().VERIFY_CUSTOMER_PAN, data)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },

  apiGetCustomerAadharDetails(data) {
    console.log("apiGetCustomerAadharDetails", data);
    return this.request(this.api().GET_CUSTOMER_AADHAR_DETAILS, data)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },

  apiVerifyCustomerAadharDetails(data) {
    // console.log("apiVerifyCustomerAadharDetails", data);
    return this.request(this.api().VERIFY_CUSTOMER_AADHAR, data)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },

  apiGetCustomerAadharInfo() {
    return this.request(this.api().GET_CUSTOMER_AADHAR_INFO)
      .exec()
      .log()
      .mapresults((response) => {
        return response;
      })
      .get();
  },
  apiAddCustomerAddressInfo(data) {
    return this.request(this.api().ADD_CUSTOMER_ADDRESS_INFO, data)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },

  apiUpdateCustomerAddressInfo(data, urldata = {}) {
    return this.request(this.api().UPDATE_CUSTOMER_ADDRESS_INFO, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiUploadCustomerDocs(data) {
    return this.request(this.api().UPLOAD_CUSTOMER_DOCS, data)
      .exec()
      .log()
      .mapresults((res) => {
        return res.data;
      })
      .get();
  },

  apiUpdateCustomerDocs(data) {
    return this.request(this.api().UPDATE_CUSTOMER_DOCS, data)
      .exec()
      .log()
      .mapresults((res) => {
        return res.data;
      })
      .get();
  },

  apiUpdateCustomerDetails(data) {
    return this.request(this.api().CUSTOMER_SELF_PROFILE_UPDATE, data)
      .exec()
      .log()
      .mapresults((res) => {
        return res.data;
      })
      .get();
  },

  apiAdminUpdateCustomerDetails(data, urldata = {}) {
    return this.request(this.api().ADMIN_UPDATE_CUSTOMER_PROFILE, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res.data;
      })
      .get();
  },

  apiDeleteCustomer(urldata = mixins.params()) {
    return this.request(this.api().DELETE_CUSTOMER)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  // APPLICATIONS
  apiGetCustomerApplications(data) {
    let payload = {
      ...data,
    };
    return this.request(this.api().GET_CUSTOMER_APPLICATIONS, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },

  apiGetCustomerApplicationJourney(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_CUSTOMER_APPLICATION_JOURNEY_BY_ID)
      .urltransform(urldata)
      .exec()
      .get();
  },

  apiCustomerAcceptSanctionedApplication(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().CUSTOMER_ACCEPT_SANCTIONED_APPLICATION)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiCustomerRejectSanctionedApplication(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().CUSTOMER_REJECT_SANCTIONED_APPLICATION)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiGetCustomerSingledApplication(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_CUSTOMER_SINGLE_APPLICATION)
      .urltransform(urldata)
      .exec()
      .get();
  },

  apiGetCustomerSanctionedApplication(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_CUSTOMER_SANCTIONED_APPLICATION)
      .urltransform(urldata)
      .exec()
      .get();
  },

  apiGetCustomerpPostSanctionedApplication(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_CUSTOMER_POST_SANCTIONED_APPLICATION)
      .urltransform(urldata)
      .exec()
      .get();
  },

  apiAddCustomerPostDocsAddressInfo(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().ADD_CUSTOMER_PERMANENT_ADDRESS_INFO, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiAddCustomerReferenceDetailsInfo(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().ADD_CUSTOMER_REFERENCE_DETAILS, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiAddCustomerAccountInfo(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().ADD_CUSTOMER_ACCOUNT_DETAILS, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiAddCustomerAdditionalDocsInfo(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().ADD_CUSTOMER_ADDITIONAL_DOCS, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiAddCustomerApplication(data) {
    return this.request(this.api().ADD_CUSTOMER_APPLICATION, data)
      .exec()
      .log()
      .get();
  },

  apiGetCustomerApplicationById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_CUSTOMER_APPLICATION_BY_ID)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },

  apiAddCustomerPayslip(data) {
    return this.request(this.api().ADD_APPLICATION_PAYSLIP, data)
      .exec()
      .log()
      .get();
  },

  apiAddCustomerBankStatament(data) {
    return this.request(this.api().ADD_APPLICATION_BANK_STATEMENT, data)
      .exec()
      .log()
      .get();
  },
  apiAddCustomerBSA(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().ADD_APPLICATION_BSA, data)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },
  apiAddCustomerQueryResponse(data) {
    return this.request(this.api().ADD_APPLICATION_QUERY_RESPONSE, data)
      .exec()
      .log()
      .get();
  },
  // ADMIN DHASBOARD API'S

  // DISBURSEMENT DETAILS

  apiGetTotalDisbursement(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_TOTAL_DISBURSEMENT_DETAILS, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },

  apiGetDisbursementDetailsByChannel(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_DISBURSEMENT_DETAILS_BY_CHANNEL, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },

  apiGetTotalDisbursementMokeData() {
    let data = {
      totalDisbursementAmt: 500000,
      totalDisbursementCount: 100,
    };
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetDsaMokeData() {
    let data = {
      dsaAmt: 500000,
      dsaCount: 100,
    };
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetDisbursementDigitalMokeData() {
    let data = {
      disbursementDigitalAmt: 500000,
      disbursementDigitalCount: 100,
    };
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetTeleCallingMokeData() {
    let data = {
      teleCallingAmt: 500000,
      teleCallingCount: 100,
    };
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetSalesOfficersMokeData() {
    let data = {
      salesOfficerAmt: 500000,
      salesOfficerCount: 100,
    };
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  // REVENUE INFO 
  apiGetRevenueTotalCollections(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_REVENUE_TOTAL_COLLECTIONS, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiGetRevenueProcessingFees(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_REVENUE_PROCESSING_FEES, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiGetRevenuePrinciple(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_REVENUE_PRINCIPLE, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiGetRevenueInterest(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_REVENUE_INTEREST, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  // ACTIVITIES
  apiGetActivitiesOverdueYesterday() {
    return this.request(this.api().GET_DASHBOARD_ADMIN_ACTIVITIES_OVERDUE_YESTERDAY)
      .exec()
      .log()
      .get();
  },
  apiGetActivitiesCollectionsYesterday() {
    return this.request(this.api().GET_DASHBOARD_ADMIN_COLLECTIONS_OVERDUE_YESTERDAY)
      .exec()
      .log()
      .get();
  },
  apiGetActivitiesOverdueToday() {
    return this.request(this.api().GET_DASHBOARD_ADMIN_ACTIVITIES_OVERDUE_TODAY)
      .exec()
      .log()
      .get();
  },
  apiGetActivitiesCollectionsToday() {
    return this.request(this.api().GET_DASHBOARD_ADMIN_COLLECTIONS_OVERDUE_TODAY)
      .exec()
      .log()
      .get();
  },

  // OVERDUE PORTFOLIO 
  apiGetDashboardAdminOverduePortfolio() {
    return this.request(this.api().GET_DASHBOARD_ADMIN_OVERDUE_PORTFOLIO)
      .exec()
      .log()
      .get();
  },

  // LEADS INFO 
  apiGetDashboardAdminLeadsInfo() {
    return this.request(this.api().GET_DASHBOARD_ADMIN_LEADS_INFO)
      .exec()
      .log()
      .get();
  },

  // OVERALL PORTFOLIO
  apiGetOverallDisbursementDetails(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_OVERALL_DISBURSEMENT_DETAILS, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },
  apiGetOverallActiveDetails(data, urldata = {}) {
    return this.request(this.api().GET_OVERALL_ACTIVE_DETAILS, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },
  apiGetOverallClosedDetails(data, urldata = {}) {
    return this.request(this.api().GET_OVERALL_CLOSED_DETAILS, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },
  apiGetOverallOverdueDetails(data, urldata = {}) {
    return this.request(this.api().GET_OVERALL_OVERDUE_DETAILS, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },

  // USER REGISTRATIONS

  apiGetTotalRegisteredUsers(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_TOTAL_REGISTERED_USERS, data)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },

  apiGetPendingRegisteredUsers(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_PENDING_REGISTERED_USERS, data)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },

  apiGetCompletedRegisteredUsers(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_COMPLETED_REGISTERED_USERS, data)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },

  apiGetAppliedRegisteredUsers(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_APPLIED_REGISTERED_USERS, data)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },

  // APPLICATION STATUS
  apiGetTotalApplicationStatus() {
    return this.request(this.api().GET_TOTAL_APPLICATION_STATUS)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },
  apiGetPendingApplicationStatus() {
    return this.request(this.api().GET_PENDING_APPLICATION_STATUS)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },
  apiGetUnderwritingApplicationStatus() {
    return this.request(this.api().GET_UNDERWRITING_APPLICATION_STATUS)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },
  apiGetSanctionedApplicationStatus() {
    return this.request(this.api().GET_SANCTIONED_APPLICATION_STATUS)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },
  apiGetApprovedApplicationStatus() {
    return this.request(this.api().GET_APPROVED_APPLICATION_STATUS)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },
  apiGetAgreementApplicationStatus() {
    return this.request(this.api().GET_AGREEMENT_APPLICATION_STATUS)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },
  apiGetDisbursedApplicationStatus() {
    return this.request(this.api().GET_DISBURSED_APPLICATION_STATUS)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },
  apiGetRejectedApplicationStatus() {
    return this.request(this.api().GET_REJECTED_APPLICATION_STATUS)
      .exec()
      .log()
      .mapresults((res) => {
        return res
      })
      .get();
  },
  // DASHBOARD SALES
  apiGetDashboardTelecallersInfo(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().GET_DASHBOARD_TELECALLERS_INFO, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  // EXPORT API'S
  apiExportApplicationDetails(data) {
    let payload = {
      ...data,
    };
    return this.request(this.api().EXPORT_APPLICATION_DETAILS, payload)
      .responsetype("arraybuffer")
      .exec()
      .log()
      .mapresults((response) => {
        return response;
      })
      .get();
  },
  apiExportAllRegisteredUsers(data) {
    let payload = {
      ...data,
    };
    return this.request(this.api().EXPORT_REGISTERED_USERS, payload)
      .responsetype("arraybuffer")
      .exec()
      .log()
      .mapresults((response) => {
        return response;
      })
      .get();
  },
  apiExportEmployeesDetails(data) {
    let payload = {
      ...data,
    };
    return this.request(this.api().EXPORT_EMPLOYEES_DETAILS, payload)
      .responsetype("arraybuffer")
      .exec()
      .log()
      .mapresults((response) => {
        return response;
      })
      .get();
  },
  apiExportLeadsDetails(data) {
    let payload = {
      ...data,
    };
    return this.request(this.api().EXPORT_LEADS_DETAILS, payload)
      .responsetype("arraybuffer")
      .exec()
      .log()
      .mapresults((response) => {
        return response;
      })
      .get();
  },
  apiExportLoanDetails(data) {
    let payload = {
      ...data,
    };
    return this.request(this.api().EXPORT_LOAN_DETAILS, payload)
      .responsetype("arraybuffer")
      .exec()
      .log()
      .mapresults((response) => {
        return response;
      })
      .get();
  },
  // ADMIN TOOLKIT

  apiGetKycAadharOtp(data) {
    return this.request(this.api().KYC_REQUEST_AADHAR_OTP, data)
      .exec().log()
      .mapresults((response) => {
        return response
      })
      .get();
  },

  apiGetKycAadharDetails(data) {
    return this.request(this.api().KYC_GET_AADHAR_DETAILS_USING_OTP, data)
      .exec().log()
      .mapresults((response) => {
        return response.data
      })
      .get();
  },

  apiGetKycPanDetails(data) {
    return this.request(this.api().KYC_GET_PAN_DETAILS, data)
      .exec().log()
      .get();
  },

  apiGetKycCibilDetails(data) {
    return this.request(this.api().KYC_GET_CIBIL_DETAILS, data)
      .exec().log()
      .get();
  },

  apiGetKycCibilDetailsMoke() {
    let data = {
      id: 3,
      fullName: "ALOK SHAH ",
      AverageOpenBalance: "178028.89",
      NoOfAccounts: "98",
      NoOfActiveAccounts: "18",
      NoOfPastDueAccounts: "1",
      NoOfWriteOffs: "0",
      MostSevereStatusWithIn24Months: "540+",
      NoOfZeroBalanceAccounts: "2",
      OldestAccount: "Credit Card on 21-09-2011",
      RecentAccount: "Personal Loan on 29-02-2024",
      SingleHighestBalance: "1847728.00",
      SingleHighestCredit: "185023.00",
      SingleHighestSanctionAmount: "2236208.00",
      TotalBalanceAmount: "3204520.00",
      TotalCreditLimit: "718700.0",
      TotalHighCredit: "548169.00",
      TotalMonthlyPaymentAmount: "115473.00",
      TotalPastDue: "16958.00",
      TotalSanctionAmount: "3324894.00",
      cibilScore: "732",
      cibilFileId: "bc889760-0756-472a-9992-3390d8bbc7da",
      profileId: "ADM-100002",
      createdAt: "2024-06-20T08:41:05.813Z",
      updatedAt: "2024-06-20T08:41:05.813Z",
    };
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetCibilUsersMoke() {
    let data = [
      {
        customerName: "Suresh Vadla",
        mobileNumber: "9988998899",
        emailAddress: "EmailID@gmail.com",
        reportGeneratedOn: new Date().toISOString(),
      },
      {
        customerName: "Manish Khati",
        mobileNumber: "9955667788",
        emailAddress: "EmailID@gmail.com",
        reportGeneratedOn: new Date().toISOString(),
      },
      {
        customerName: "Yasha Agarwal",
        mobileNumber: "9988776655",
        emailAddress: "EmailID@gmail.com",
        reportGeneratedOn: new Date().toISOString(),
      },
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiGetCibilUsersHistory(data) {
    return this.request(this.api().KYC_GET_CIBIL_USERS_HISTORY, data)
      .exec().log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },

  apiGetAllEquifaxAccounts(data) {
    return this.request(this.api().GET_ALL_EQUIFAX_ACCOUNTS, data)
      .exec().log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },

  apiGetAllDisbursementApplications(data) {
    return this.request(this.api().GET_ALL_DISBURSEMENT_APPLICATIONS, data)
      .exec().log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },

  apiGetAllLoanRepayments(data) {
    return this.request(this.api().GET_ALL_LOAN_REPAYMENTS, data)
      .exec().log()
      .mapresults((response) => {
        return {
          data: response?.data,
          total: response?.count,
        };
      })
      .get();
  },

  apiGetTudfFile(data) {
    return this.request(this.api().GET_TUDF_FILE, data)
      .exec().log()
      .get();
  },

  // (POST API CALL WITH QUERY PARAMETERS)
  apiGetEquifaxExcelFile(urldata = {}) {
    return this.request(this.api().GET_EQUIFAX_EXCEL_FILE, {}, {}, urldata)
      .responsetype("arraybuffer")
      .exec()
      .log()
      .get();
  },
};
