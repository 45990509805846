import React, { useEffect, useState } from "react";

import MainPagetitle from "../../../layouts/MainPagetitle";
import RegisteredUserSearch from "./RegisteredUserSearch/RegisteredUserSearch";
import { Dropdown } from "react-bootstrap";

const RegisteredUsers = (props) => {
  const isAdmin = props?.$store?.persona === _enum.ROUTE_PERSONAS.admin;
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "customerName",
      title: "Name",
      sorter: (a, b) => a.customerName.length - b.customerName.length,
      render: (text, record) => {
        return (
          <div className="products">
            <div>
              <h6 className="text-primary">{record.customerName}</h6>
              <span>{record.customerId}</span>
            </div>
          </div>
        );
      },
    },
    {
      dataIndex: "mobileNumber",
      title: "Mobile No.",
    },
    {
      dataIndex: "emailAddress",
      title: "Email ID",
    },
    {
      dataIndex: "createdAt",
      title: "Registration Date",
      render: (text, record) => {
        let date = new Date(text).dateToDDMMYYYY(new Date(text));
        return date;
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      render: (text, record) => {
        let mappedStatus = _enum.STATUS_COLOR_MAP[record.status] || "";
        return (
          <Dropdown
            className={Object.className({
              "status-dropdown": true,
              "no-arrow pe-none": true,
            })}
          >
            <Dropdown.Toggle as="div" className={`${mappedStatus}`}>
              <span>{record?.status?.toTitleCase() || "-"}</span>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
  ];

  const onPageChange = (pageData) => {
    console.log({ onPageChange: pageData });
    setSearch({
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 10,
    });
  };

  const onSearch = (data, reset) => {
    console.log("onSearch on leads", data, reset);
    if (Object.keys(data).length > 0) {
      let defaultSearchData = Object.only(data, ["limit", "page"]);
      let payload;
      if (reset) {
        payload = {
          ...defaultSearchData,
        };
      } else {
        payload = {
          ...data,
          page: 1,
          limit: 10,
        };
      }
      console.log({ payload });
      setSearch(payload);
    }
  };

  const getEmployees = (params = {}, reset) => {
    console.log({ params, reset });
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, [
        "limit",
        "page",
        // "order_by",
        // "order_direction",
      ]);

      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
        ...params,
      };
      // console.log({ defaultSearchData: payload });
    } else {
      payload = {
        ...search,
        ...params,
      };
    }

    const str = window.location.search;
    const urlparams = Object.fromEntries(new URLSearchParams(str));
    console.log(urlparams);

    if (urlparams.hasOwnProperty("status")) {
      payload.status = urlparams?.status;
    }

    props.apis.apiGetAllRegisteredUsers(payload).then(({ data, total }) => {
      setTableData(data);
      setIsFetching(false);
      setRowcount(total);
    });
  };

  const deleteEmployee = (record) => {
    console.log({ deleteEmployee: record });
    if (record.department === "Adminstration") {
      swal2.fire({
        title: "Administrative accounts cannot be deleted",
        icon: "error",
        iconColor: "#FF4D4D",
        showCancelButton: false,
        timer: 3000,
      });
    } else {
      swal2
        .fire({
          title: "Are you sure you want to delete this user?",
          icon: "warning",
          iconColor: "#FF4D4D",
          showCancelButton: true,
          confirmButtonColor: "#FF4D4D",
          cancelButtonColor: "##D6DBE7",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            props.apis
              .apiDeleteCustomer({ customerId: record.customerId })
              .then((res) => {
                console.log({ apiDeleteCustomer: res });
                swal2.fire({
                  title: "Customer Deleted",
                  text: "Customer deleted successfully !!",
                  icon: "success",
                  iconColor: "#FF4D4D",
                  timer: 4000,
                });
                getEmployees();
              });
          }
        });
    }
  };

  const viewEmployee = (record) => {
    setEditmode(false);
    setViewmode(true);
    props.navigate(`/app/userdetails?customerId=${record.customerId}`);
    // GET EMPLOYEE BY ID
    // props.apis
    //   .apiGetEmployeeById({ employeeId: record.employeeId })
    //   .then((data) => {
    //     console.log({ apiGetEmployeeById: data });
    //     let filteredData = Object.only(
    //       data,
    //       modalFields.map((v) => v.name)
    //     );
    //     console.log({ filteredData });
    //     setPopupdata(filteredData);
    //     setEditData(data);
    //     openModal();
    //   });
  };

  const downloadExcel = async () => {
    let payload = {};
    props.apis.apiExportAllRegisteredUsers(payload).then(async (res) => {
      if (res) {
        let data = await res;
        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute(
          "download",
          `registered_users_${new Date().toDate()}.xlsx`
        );
        link.click();
      }
    });
  };

  useEffect(() => {
    getEmployees();
  }, [search]);

  props.$setNode({
    props,
    viewmode,
    editmode,
  });

  return (
    <>
      <MainPagetitle
        pageTitle={"Registered User"}
        mainTitle="Registered User"
        parentTitle={"Home"}
        pageIcon={imagepaths.AdminLeadsIconActive}
      />
      <div
        className={Object.className({
          "container-fluid": true,
        })}
      >
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption-container">
                  <h4 className="heading mb-0">Registered Users</h4>
                  <RegisteredUserSearch
                    onSearch={(...arg) => onSearch(...arg)}
                  />
                </div>
                {isAdmin && (
                  <div className="action_btn_group text-end">
                    <button
                      type="button"
                      className="btn btn-primary-transparent file_download_btn text-primary me-5"
                      onClick={(...args) => downloadExcel(...args)}
                    >
                      <i className="fa fa-download me-2"></i>
                      Download Excel
                    </button>
                  </div>
                )}
                <AppTable
                  data={tableData}
                  columns={columns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  editable={false}
                  viewAction={(e) => viewEmployee(e)}
                  deleteAction={(e) => deleteEmployee(e)}
                  loading={isFetching}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default constore(RegisteredUsers);
