export default {

    // MASTER - LOCATION
    ADD_MASTER_LOCATION: {
        url: "/location",
        method: "post",
    },
    GET_MASTER_LOCATIONS: {
        url: "/location",
        method: "get",
    },
    UPDATE_MASTER_LOCATION: {
        url: "/location/{locationId}",
        method: "put",
    },
    GET_MASTER_LOCATION_BY_ID: {
        url: "/location/{locationId}",
        method: "get",
    },
    DELETE_MASTER_LOCATION: {
        url: "/location/{locationId}",
        method: "delete",
    },

    // MASTER - ROLES
    ADD_MASTER_ROLE: {
        url: "/roles/role",
        method: "post",
    },
    ADD_MASTER_DESIGNATION: {
        url: "/roles/designation",
        method: "post",
    },
    ADD_MASTER_DEPARTMENT: {
        url: "/roles/department",
        method: "post",
    },

    GET_MASTER_ROLES: {
        url: "/master-role",
        method: "get",
    },
    GET_MASTER_DESIGNATIONS: {
        url: "/roles/designations",
        method: "get",
    },
    GET_MASTER_DEPARTMENTS: {
        url: "/roles/departments",
        method: "get",
    },

    DELETE_MASTER_ROLE: {
        url: "/roles/role/{role}",
        method: "delete",
    },
    DELETE_MASTER_DESIGNATION: {
        url: "/roles/designation/{designation}",
        method: "delete",
    },
    DELETE_MASTER_DEPARTMENT: {
        url: "/roles/department/{department}",
        method: "delete",
    },

    // BANK-NAMES
    ADD_BANK_NAME: {
        url: "/bankNames",
        method: "post",
    },
    GET_BANK_NAMES: {
        url: "/bankNames",
        method: "get",
    },
    GET_BANK_BY_ID: {
        url: "/bankNames/{bankId}",
        method: "get",
    },
    UPDATE_BANK_NAME: {
        url: "/bankNames/{bankId}",
        method: "put",
    },
    DELETE_BANK_NAME: {
        url: "/bankNames/{bankId}",
        method: "delete",
    },

    // INTEREST RATE
    ADD_INTEREST_RATES: {
        url: "/interestRates",
        method: "post",
    },
    GET_INTEREST_RATES: {
        url: "/interestRates",
        method: "get",
    },
    GET_INTEREST_RATE_BY_ID: {
        url: "/interestRates/{interestRateId}",
        method: "get",
    },
    UPDATE_INTEREST_RATE: {
        url: "/interestRates/{interestRateId}",
        method: "put",
    },
    DELETE_INTEREST_RATE: {
        url: "/interestRates/{interestRateId}",
        method: "delete",
    },

    // PRODUCT TYPES
    ADD_PRODUCT_TYPE: {
        url: "/product-type",
        method: "post",
    },
    GET_PRODUCT_TYPES: {
        url: "/product-type",
        method: "get",
    },
    DELETE_PRODUCT_TYPE: {
        url: "/product-type/{productType}",
        method: "delete",
    },

    // PINCODE
    GET_INDIVIDUAL_BY_PINCODE: {
        url: "/master-pincode/{pincode}",
        method: "get",
    },

    // MASTER COMPANY
    ADD_MASTER_COMPANY: {
        url: "/master-company",
        method: "post",
    },
    GET_ALL_MASTER_COMPANIES: {
        url: "/master-company",
        method: "get",
    },
}