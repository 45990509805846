import React, { useEffect, useState } from "react";
import MainPagetitle from "../../../layouts/MainPagetitle";
import RepaymentModal from "../LoanDetails/Repayments/RepaymentModal";

const UserDetails = (props) => {
  var BASE_URL = `${endpoints[environment].api_base}/files/download/`;
  // ----------------------------------------------------------------
  const [search, setSearch] = useState({
    // page: 1,
    // limit: 10,
  });
  const [userdata, setUserdata] = useState(null);
  const getCustomer = () => {
    let payload = {
      customerId: props.urlparams.params.customerId,
    };
    props.apis.apiGetCustomerOverallInfo(payload).then((response) => {
      console.log({ response });
      setUserdata(response);
    });
  };
  useEffect(() => {
    getCustomer();
  }, []);

  const handleDelete = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    console.log({ value });
    let properties = {
      title: `Are you sure you want to delete this user's ${value?.toTitleCase()} details?`,
      icon: "info",
      iconColor: "#FF4D4D",
      showCancelButton: true,
      confirmButtonColor: "#FF4D4D",
      cancelButtonColor: "##D6DBE7",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    };
    let successProperties = {
      title: `${value?.toTitleCase()} details deleted successfully !!`,
      icon: "success",
      iconColor: "#FF4D4D",
      timer: 4000,
    };
    swal2.fire(properties).then((result) => {
      if (result.isConfirmed) {
        const resolve = () => {
          swal2.fire(successProperties);
          getSingleProfileInfo();
        };
        // if (value === "aadhar") {
        //   props.apis
        //     .apiDeleteAadhar({ profileId: empdata?.profileId })
        //     .then(() => resolve());
        // }
        // if (value === "pan") {
        //   props.apis
        //     .apiDeletePan({ profileId: empdata?.profileId })
        //     .then(() => resolve());
        // }
      }
    });
  };
  // ----------------------------------------------------------------
  const [editData, setEditData] = useState([]);
  const [editmode, setEditmode] = useState(false);
  const [viewmode, setViewmode] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [popupfields, setPopupfields] = useState({});
  const [popupdata, setPopupdata] = useState({});
  const [errorMessage, setErrorMessage] = useState({ name: "", message: "" });
  const [defaultCompanies, setDefaultCompanies] = useState([]);

  const [modalFields, setModalFields] = useState([
    {
      name: "companyname",
      label: "Company Name",
      type: "select",
      options: [],
      searchFn: (value) => handleCompanyNameSearch(value),
      onChange: (...args) => handleCompanyChange(...args),
      placeholder: "Please enter",
      required: true,
    },
    {
      name: "newcompanyname",
      label: "New Company Name",
      type: "text",
      placeholder: "Please enter",
      disabled: true,
    },
    {
      name: "monthlySalary",
      label: "Monthly Salary",
      type: "text",
      placeholder: "Please enter",
    },
    {
      name: "salaryMode",
      label: "Mode of Salary",
      type: "select",
      options: _enum.MODE_OF_SALARY,
      placeholder: "Please enter",
    },
    {
      name: "officialEmail",
      label: "Office Email",
      type: "email",
      placeholder: "Please enter",
      required: true,
    },
  ]);

  const handleCompanyNameSearch = (params) => {
    // console.log(params);
    let value = params?.state?.search;
    let payload = {
      companyName: value,
    };
    if (value.length >= 3) {
      mixins.debounce(() => {
        props.apis.apiGetMasterCompanies(payload).then(({ data }) => {
          if (data) {
            let companyNameInput = document.querySelector(
              "[name='appinput-companyname'].form-group"
            );
            let companySelect = companyNameInput.vnode.refs.select;
            let { searchResults } = companySelect.state;
            let datawithkey = data?.options("companyName");
            // console.log({ searchResults, datawithkey });
            searchResults = datawithkey;

            if (searchResults.length === 0) {
              companySelect.setState({
                searchResults: [{ key: "OTHERS", label: "Others" }],
              });
            } else {
              companySelect.setState({ searchResults });
            }
          }
        });
      }, 1000);
    }
  };

  const handleCompanyChange = (value) => {
    let key = value[0]?.key;

    var newCompanyNameInput = document.querySelector("[name='newcompanyname']");
    if (key === "OTHERS") {
      newCompanyNameInput.disabled = false;
    } else {
      newCompanyNameInput.disabled = true;
      newCompanyNameInput.value = "";
      newCompanyNameInput.defaultValue = "";
    }
  };

  const getCompanies = () => {
    let payload = {
      ...search,
    };
    props.apis.apiGetMasterCompanies(payload).then(({ data }) => {
      // console.log({ data });
      if (data) {
        setDefaultCompanies(data);
        let companyNameField = modalFields.find(
          (field) => field.name === "companyname"
        );
        companyNameField.options = data.options("companyName");
        setModalFields([...modalFields]);
      }
    });
  };

  const openModal = () => {
    setOpenCanvas(true);
  };

  const closeModal = () => {
    setOpenCanvas(false);
  };

  const onModalClose = () => {
    console.log("onModalClose");
    closeModal();
    setPopupfields({});
    setViewmode(false);
    setEditmode(false);
    setEditData({});
    setErrorMessage({ name: "", message: "" });
  };

  const editIncomeDetails = (e) => {
    setEditmode(true);
    setViewmode(false);

    let editdata = {
      companyname: userdata?.companyname,
      newcompanyname: "",
      salaryMode: userdata?.salaryMode,
      monthlySalary: userdata?.monthlySalary,
      officialEmail: userdata?.officialEmail,
    };

    let fields = (modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    console.log({ fields, editdata });
    setEditData(editdata);
    setPopupfields(fields);
    setPopupdata(editdata);
    setOpenCanvas(true);
  };

  const updateFields = (fields, data) => {
    let result = fields?.map((field) => {
      let filteredData = Object.only(
        data,
        fields.map((v) => v.name)
      );

      if (field.name === "companyname") {
        // console.log(field, data[field?.name]);
        var companyNameInput = document.querySelector(
          "[name='appinput-companyname'].form-group"
        );
        var newCompanyNameInput = document.querySelector(
          "[name='newcompanyname']"
        );
        // console.log(companyNameInput, newCompanyNameInput);

        if (companyNameInput) {
          var companySelect = companyNameInput?.vnode.refs.select;
          // console.log({ companySelect });

          if (companySelect && companySelect?.state?.values?.length === 0) {
            let value = filteredData[field.name];
            let payload = {
              companyName: value,
            };
            if (value?.length >= 3) {
              props.apis.apiGetMasterCompanies(payload).then(({ data }) => {
                if (data) {
                  // console.log({ "search data": data });
                  if (companySelect) {
                    companySelect = companyNameInput.vnode.refs.select;
                    let { searchResults } = companySelect?.state || {};
                    let datawithkey = data?.options("companyName");
                    // console.log({ searchResults, datawithkey });

                    if (data.length === 0) {
                      companySelect?.setState(
                        {
                          searchResults: [{ key: "OTHERS", label: "Others" }],
                          values: [{ key: "OTHERS", label: "Others" }],
                        },
                        () => {
                          companySelect?.setState({
                            searchResults,
                            values: [{ key: "OTHERS", label: "Others" }],
                          });
                        }
                      );
                      newCompanyNameInput.value = value;
                    } else {
                      companySelect?.setState({
                        searchResults,
                        values: [
                          {
                            key: datawithkey[0].key,
                            label: datawithkey[0].label,
                          },
                        ],
                      });
                    }
                  }
                }
              });
            }
          }
        }
      }
      return {
        ...field,
        defaultValue: data[field?.name],
        // disabled: true,
      };
    });
    // console.log(result);
    return result;
  };

  const handleSubmit = (e, data, form) => {
    e.preventDefault();
    let payload = {
      ...Object.except(data, "newcompanyname"),
    };
    if (data?.companyname === "OTHERS") {
      payload.companyname = data.newcompanyname;
    } else {
      payload.companyname = data.companyname;
    }
    console.log({ data, payload });

    props.apis
      .apiAdminUpdateCustomerDetails(payload, {
        customerId: userdata?.customerId,
      })
      .then((res) => {
        swal2.fire({
          title: "Income Details updated successfully.",
          icon: "success",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
        closeModal();
        getCustomer();
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    setTimeout(() => {
      let updatedFields = updateFields(modalFields, editData);
      console.log({ updatedFields });
    }, 500);
  }, [editData]);

  useEffect(() => {
    getCompanies();
  }, []);

  // ----------------------------------------------------------------

  props.$setNode({
    props,
    userdata,
  });
  if (userdata) {
    var {
      customerId,
      customerName,
      emailAddress,
      mobileNumber,
      dob,
      gender,
      dateOfJoining,
      workLocation,
      status,
      isSalaried,
      companyname,
      monthlySalary,
      salaryMode,
      officialEmail,
      netMonthlyIncome,
      natureOfBusiness,
      panFileMetaData,
      aadharFrontFileMetaData,
      aadharBackFileMetaData,
      photoFileMetaData,
      CustomerAddressInfo,
      customerPanCardInfo,
      customerAadharCardInfo,
    } = userdata;
    //

    var date_of_birth = new Date(dob).dateToDDMMYYYY(new Date(dob));
    var age = new Date(dob).getAge();
    var address;
    if (CustomerAddressInfo.length > 0) {
      address =
        (CustomerAddressInfo[0]?.address1 !== null
          ? CustomerAddressInfo[0]?.address1?.toTitleCase()
          : "") +
        (CustomerAddressInfo[0]?.address2 !== null
          ? `, ${CustomerAddressInfo[0]?.address2?.toTitleCase()}`
          : "") +
        (CustomerAddressInfo[0]?.address3 !== null
          ? `, ${CustomerAddressInfo[0]?.address3?.toTitleCase()}.`
          : "");
    } else {
      address = "";
    }
    var pincode = CustomerAddressInfo[0]?.pincode || "-";
    // ----------------------------------------------------------------
    let name = customerName;
    let firstLetter = name.charAt(0); // Get the first letter of the first word
    let secondLetterIndex = name.indexOf(" ") + 1; // Get the index of the first letter of the word after the space
    let secondLetter = name.charAt(secondLetterIndex); // Get the second letter of the word after the space
    let result = firstLetter + secondLetter; // Concatenate the first and second letters
    var initialValue = result ? result : "";
    // ----------------------------------------------------------------

    var panFileId = panFileMetaData?.fileId;
    var aadharFrontFileId = aadharFrontFileMetaData?.fileId;
    var aadharBackFileId = aadharBackFileMetaData?.fileId;
    var photoFileId = photoFileMetaData?.fileId;
  }
  return (
    <>
      <MainPagetitle
        mainTitle="Registered User"
        back={`${customerName || "User"}`}
        backLink="/app/registeredUsers"
        pageTitle={"Registered User"}
        parentTitle={"Home"}
      />
      <div
        className={Object.className({
          "container-fluid": true,
        })}
      >
        <div className="row g-0">
          <div className="col-xl-12">
            <div className="info_card">
              <div className="content">
                <div className="name_card">
                  <div className="avatar_circle">{initialValue || ""}</div>
                  <h2 className="name">{customerName || "-"}</h2>
                </div>
                <div className="content_list_container row">
                  <ul className="col-12 col-md-6 col-lg-4 content_list">
                    <li>
                      <span className="icon">User ID: </span>
                      <span>{customerId || "-"}</span>
                    </li>
                    <li>
                      <span className="icon">Gender: </span>
                      <span>{gender || "-"}</span>
                    </li>
                    <li>
                      <span className="icon">Date of Birth:</span>
                      <span>{date_of_birth || "-"}</span>
                    </li>
                    <li>
                      <span className="icon">Age: </span>
                      <span>{age || "-"}</span>
                    </li>
                  </ul>
                  <ul className="col-12 col-md-6 col-lg-4 content_list">
                    <li>
                      <span className="icon">Mobile No:</span>
                      <span>{mobileNumber || "-"}</span>
                    </li>
                    <li>
                      <span className="icon">Email ID:</span>
                      <span>{emailAddress || "-"}</span>
                    </li>
                    <li>
                      <span className="icon">PIN Code: </span>
                      <span>{pincode || "-"}</span>
                    </li>
                  </ul>
                  <ul className="col-12 col-md-6 col-lg-4 content_list">
                    <li>
                      <span className="icon">Address:</span>
                      <span className="whitespace-preline">
                        {address || "-"}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row mx-auto g-0">
              <div className="col-12 col-lg-4">
                <div className="card profile_card income_details_card">
                  <div className="card-header p-0 border-0">
                    <div className="d-flex align-items-center mb-3">
                      <img
                        src={imagepaths.walletMoneyIcon}
                        alt="walletMoneyIcon"
                        className="card_icon"
                      />
                      <h5 className="title ms-2 mb-0">Income Details</h5>
                    </div>
                    <div className="action_box d-flex align-items-center">
                      <button
                        type="button"
                        className="text-primary"
                        onClick={(...args) => editIncomeDetails(...args)}
                      >
                        <i className="fa fa-pencil"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="content_box_container">
                      <ul className="content_box">
                        <li>
                          <span className="label">Company Name:</span>
                          <span>{companyname || "-"}</span>
                        </li>
                        <li>
                          <span className="label">Mode of Salary:</span>
                          <span>{salaryMode || "-"}</span>
                        </li>
                        <li>
                          <span className="label">Monthly Salary:</span>
                          <span>{monthlySalary?.cur() || "-"}</span>
                        </li>
                        <li>
                          <span className="label">Office Email:</span>
                          <span>{officialEmail || "-"}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                {/* AADHAR */}
                <div className="card profile_card">
                  <div className="card-header py-0 border-0">
                    <div className="d-flex align-items-start">
                      <img src={imagepaths.aadharIcon} alt="aadhar" />
                      <h5 className="title ms-2">Aadhar</h5>
                    </div>
                    <div
                      className={Object.className({
                        "action_box d-flex align-items-center": true,
                      })}
                    >
                      {customerAadharCardInfo &&
                      Object.keys(customerAadharCardInfo).length > 0 ? (
                        <>
                          <button
                            type="button"
                            className="text-primary"
                            onClick={(...args) =>
                              handleDelete(...args, "aadhar")
                            }
                          >
                            <i class="fa-regular fa-trash-can"></i>
                          </button>
                          <div className="separator"></div>
                          <i className="fa fa-check check_icon"></i>
                        </>
                      ) : (
                        <i class="fa-solid fa-xmark check_icon"></i>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="card-body py-0">
                    <h5>Aadhar no</h5>
                    <p className="">{customerAadharCardInfo?.uid || "-"}</p>
                    <h5>Address</h5>
                    <p className="">{customerAadharCardInfo?.address || "-"}</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                {/* PAN */}
                <div className="card profile_card">
                  <div className="card-header py-0 border-0">
                    <div className="d-flex align-items-start">
                      <img src={imagepaths.panIcon} alt="aadhar" />
                      <h5 className="title ms-2">Pan</h5>
                    </div>
                    <div
                      className={Object.className({
                        "action_box d-flex align-items-center": true,
                      })}
                    >
                      {customerPanCardInfo &&
                      Object.keys(customerPanCardInfo).length > 0 ? (
                        <>
                          <button
                            type="button"
                            className="text-primary"
                            onClick={(...args) => handleDelete(...args, "pan")}
                          >
                            <i class="fa-regular fa-trash-can"></i>
                          </button>
                          <div className="separator"></div>
                          <i className="fa fa-check check_icon"></i>
                        </>
                      ) : (
                        <i class="fa-solid fa-xmark check_icon"></i>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="card-body py-0">
                    <h5>Name as per PAN</h5>
                    <p className="">{customerPanCardInfo?.name || "-"}</p>
                    <h5>PAN No</h5>
                    <p className="">{customerPanCardInfo?.number || "-"}</p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card profile_card">
                  <div className="card-header p-0 border-0">
                    <div className="d-flex align-items-center mb-3">
                      <img
                        src={imagepaths.cardGaurdIcon}
                        alt="cardGaurdIcon"
                        className="card_icon"
                      />
                      <h5 className="title ms-2 mb-0">Docs Details</h5>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="docs_card_container row g-0">
                      <div className="col-6 col-sm-4 col-md-6 col-lg-3">
                        <div className="docs_card">
                          <img
                            src={imagepaths.docsCardRedIcon}
                            alt="docsCardRedIcon"
                          />
                          <h2 className="title">PAN Card Front</h2>
                          {panFileId && panFileId?.length !== 0 ? (
                            <a
                              href={`${BASE_URL}${panFileId}`}
                              className="text-primary"
                            >
                              Download
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {/* <div className="col-6 col-sm-4 col-md-6 col-lg-3">
                        <div className="docs_card">
                          <img
                            src={imagepaths.docsCardRedIcon}
                            alt="docsCardRedIcon"
                          />
                          <h2 className="title">Aadhar Card (Front)</h2>
                          {aadharFrontFileId &&
                          aadharFrontFileId?.length !== 0 ? (
                            <a
                              href={`${BASE_URL}${aadharFrontFileId}`}
                              className="text-primary"
                            >
                              Download
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-6 col-sm-4 col-md-6 col-lg-3">
                        <div className="docs_card">
                          <img
                            src={imagepaths.docsCardRedIcon}
                            alt="docsCardRedIcon"
                          />
                          <h2 className="title">Aadhar Card (Back)</h2>
                          {aadharBackFileId &&
                          aadharBackFileId?.length !== 0 ? (
                            <a
                              href={`${BASE_URL}${aadharBackFileId}`}
                              className="text-primary"
                            >
                              Download
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </div> */}
                      <div className="col-6 col-sm-4 col-md-6 col-lg-3">
                        <div className="docs_card">
                          <img
                            src={imagepaths.docsCardRedIcon}
                            alt="docsCardRedIcon"
                          />
                          <h2 className="title">Photo</h2>
                          {photoFileId && photoFileId?.length !== 0 ? (
                            <a
                              href={`${BASE_URL}${photoFileId}`}
                              className="text-primary"
                            >
                              Download
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RepaymentModal
        id="employeeCanvas"
        title={`${
          editmode
            ? "Edit Income Details"
            : viewmode
            ? "View Income Details"
            : "Add Income Details"
        }`}
        show={openCanvas}
        onClose={() => onModalClose()}
        data={popupdata}
        editdata={editData}
        fields={popupfields}
        editmode={editmode}
        onSubmit={(...args) => handleSubmit(...args)}
        submitText={`${editmode ? "Update" : "Submit"}`}
        disableallfields={viewmode ? true : false}
        errorMessage={errorMessage}
      />
    </>
  );
};
export default constore(UserDetails);
