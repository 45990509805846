export default {
  // APPLICATIONS
  ADD_APPLICATION: {
    url: "/applications",
    method: "post",
  },
  GET_APPLICATIONS: {
    url: "/customer/applications",
    method: "get",
  },
  GET_APPLICATION_BY_ID: {
    url: "/applications/{applicationNumber}",
    method: "get",
  },
  UPDATE_APPLICATION: {
    url: "/applications/{applicationNumber}",
    method: "put",
  },
  DELETE_APPLICATION: {
    url: "/applications/{applicationNumber}",
    method: "delete",
  },
  REOPEN_APPLICATION: {
    url: "/admin/application/reopen/{applicationNumber}",
    method: "put",
  },
  SOFT_DELETE_APPLICATION: {
    url: "/admin/application/{applicationId}",
    method: "delete",
  },
  PAYIN: {
    url: "/loan-pg/payin/web/request",
    method: "post",
  },
  SEND_LOAN_AMOUNT_TO_CUSTOMER: {
    url: "/loan-pg/payout/disburse/{loanAccountNumber}",
    method: "post",
  },
  ADMIN_FETCH_TRANSACTION_STATUS: {
    url: "/loan-pg/payout/status/{refereceId}",
    method: "get",
  },
  ADMIN_MANUALLY_ADD_CUSTOMER_PAYOUT_DETAILS: {
    url: "/loan-pg/payout/manual/{loanAccountNumber}",
    method: "post",
  },
  GET_CUSTOMER_PAYIN_TRANSACTIONS: {
    url: "/loan-pg/payin/customer/transaction",
    method: "get",
  },
};
