import React, { useEffect, useState } from "react";

import { Dropdown, Modal } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";
import EquifaxReportSearch from "./EquifaxReportSearch/EquifaxReportSearch";

const EquifaxReport = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const [showTudfModal, setShowTudfModal] = useState(false);

  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "CustomerName1",
      title: "Name",
      sorter: (a, b) => a.CustomerName1.length - b.CustomerName1.length,
      render: (text, record) => {
        return <h6 className="text-primary">{record.CustomerName1}</h6>;
      },
    },
    {
      dataIndex: "loanAccountNumber",
      title: "LAN No.",
    },
    {
      dataIndex: "CustomerAccountSanctionedLoanAmount",
      title: "Loan Amt.",
      render: (text, record) => {
        return <span>{text?.cur() || "-"}</span>;
      },
    },
    {
      dataIndex: "CustomerAccountDisbursementDate",
      title: "Loan Start Date",
      render: (text, record) => {
        if (text) {
          let date = new Date(text).dateToDDMMYYYY(new Date(text));
          return date || "-";
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "CustomerAccountLoanDueDate",
      title: "Loan End Date",
      render: (text, record) => {
        if (text) {
          let date = new Date(text).dateToDDMMYYYY(new Date(text));
          return date || "-";
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "status",
      title: "Loan Status",
      render: (text, record) => {
        let mappedStatus =
          _enum.STATUS_COLOR_MAP[record?.status?.toLowerCase()] || "";
        return (
          <Dropdown className="status-dropdown no-arrow pe-none">
            <Dropdown.Toggle as="div" className={`${mappedStatus}`}>
              <span>{record?.status?.toTitleCase() || "-"}</span>
              <i class="fa-solid fa-chevron-down ms-2"></i>
            </Dropdown.Toggle>
          </Dropdown>
        );
      },
    },
    {
      dataIndex: "CustomerAccountLoanClosingDate",
      title: "loan closer date",
      render: (text, record) => {
        if (text) {
          let date = new Date(text).dateToDDMMYYYY(new Date(text));
          return date || "-";
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "overDueDays",
      title: "Overdue days",
      render: (text, record) => {
        return <>{`${text} days` || "-"}</>;
      },
    },
    {
      dataIndex: "dpdBand",
      title: "Overdue Band",
      render: (text, record) => {
        return <>{text || "-"}</>;
      },
    },
  ];

  const onPageChange = (pageData) => {
    console.log({ onPageChange: pageData });
    let payload = {
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 10,
    };
    getEquifaxData(payload, false);
  };

  const getEquifaxData = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, ["page", "limit"]);
      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
        ...params,
      };
    } else {
      payload = {
        ...search,
        ...params,
      };
    }
    props.apis.apiGetAllEquifaxAccounts(payload).then(({ data, total }) => {
      console.log({ apiGetAllEquifaxAccounts: data, total });
      setTableData(data);
      setIsFetching(false);
      setRowcount(total);
    });
  };

  const onSearch = (data, reset) => {
    console.log("onSearch on leads", data, reset);

    let alteredData = {};

    if (data?.activityStartDate?.length > 0) {
      alteredData.activityStartDate = new Date(
        data?.activityStartDate
      ).toISOString();
    }
    if (data?.activityEndDate?.length > 0) {
      alteredData.activityEndDate = new Date(
        data?.activityEndDate
      ).toISOString();
    }

    if (Object.keys(data).length === 0) {
      setSearch({
        ...alteredData,
        ...search,
      });
      getEquifaxData(alteredData, reset);
    } else {
      setSearch({
        ...alteredData,
        ...search,
      });
      getEquifaxData(alteredData, reset);
    }
  };

  const openTudfModal = () => {
    let loanAccountNumbers = [...selectedRowKeys];
    if (loanAccountNumbers?.length === 0) {
      swal2.fire({
        title: "Please select any application",
        icon: "info",
        iconColor: "#FF4D4D",
      });
    } else {
      setShowTudfModal(true);
    }
  };

  const closeTudfModal = () => {
    setShowTudfModal(false);
  };

  const handleTudfSubmit = (e, data) => {
    let payload = {
      ProcessorUserID: "031FP11223",
      ProcessorShortName: "ABCBANK",
      CycleIdentification: data?.CycleIdentification,
      ReportingPassword: data?.ReportingPassword,
      AuthenticationMethod: data?.AuthenticationMethod,
      loanAccountNumbers: [...selectedRowKeys],
    };
    console.log({ payload });

    props.apis.apiGetTudfFile(payload).then(async (res) => {
      if (res) {
        console.log({ res });
        let data = await res;
        let blob = new Blob([data], {
          type: "text/html; charset=utf-8",
        });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", `report_${new Date().toDate()}.txt`);
        link.click();
        closeTudfModal();
      }
    });
  };

  const downloadExcel = async () => {
    let startDateInput = document?.querySelector(
      '[name="appinput-activityStartDate"] input[name="activityStartDate"]'
    );
    let endDateInput = document?.querySelector(
      '[name="appinput-activityEndDate"] input[name="activityEndDate"]'
    );

    if (
      startDateInput?.value?.length === 0 &&
      endDateInput?.value?.length === 0
    ) {
      swal2.fire({
        title: "Please select a start date and end date",
        timer: 3000,
        icon: "warning",
        iconColor: "#FF4D4D",
      });
    } else {
      let urldata = {
        activityStartDate: new Date(startDateInput?.value).toISOString(),
        activityEndDate: new Date(endDateInput?.value).toISOString(),
      };
      console.log({ urldata });

      props.apis.apiGetEquifaxExcelFile(urldata).then(async (res) => {
        if (res) {
          let data = await res;
          let blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute(
            "download",
            `equifax_excel_report_${new Date().toDate()}.xlsx`
          );
          link.click();
        }
      });
    }
  };

  // ROW SELECTION
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    getEquifaxData();
  }, []);

  props.$setNode({
    props,
  });

  return (
    <>
      <MainPagetitle
        mainTitle="Reports"
        back={"Equifax Report"}
        backLink={`/app/equifaxreports`}
        pageTitle={"Equifax Report"}
        parentTitle={"Home"}
        pageIcon={imagepaths.reportsIconActive}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption-container">
                  <h4 className="heading mb-0">Equifax Report</h4>
                  <EquifaxReportSearch
                    onSearch={(...arg) => onSearch(...arg)}
                  />
                </div>
                <div className="action_btn_group text-end mb-4">
                  <button
                    type="button"
                    className="btn btn-primary-transparent file_download_btn text-primary me-2"
                    onClick={(...args) => openTudfModal(...args)}
                  >
                    <i className="fa fa-download me-2"></i>
                    Download TUDF Format
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary ms-1 me-5"
                    onClick={(...args) => downloadExcel(...args)}
                  >
                    <i className="fa fa-download me-2"></i>
                    Download Excel
                  </button>
                </div>
                <AppTable
                  data={tableData}
                  columns={columns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  editable={false}
                  viewable={false}
                  deletable={false}
                  loading={isFetching}
                  checkbox={true}
                  rowSelection={rowSelection}
                  isCustomRowKey={true}
                  customRowKey="loanAccountNumber"
                  customPageSizeOptions={[10, 20, 50, 100, 500]}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TUDF DOWNLOAD MODAL */}
      <Modal
        show={showTudfModal}
        onHide={closeTudfModal}
        centered
        backdrop="static"
        className="consent_otp_modal"
      >
        <Modal.Header
          closeButton
          className="border-0 py-4 d-flex align-item-start"
        >
          <h2 className="fs-3">Download TUDF Format</h2>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="row g-3 mb-4">
            <AppForm onSubmit={handleTudfSubmit}>
              <AppInput
                name="CycleIdentification"
                label="Reporting Month"
                type="select"
                options={_enum.MONTH_BY_NUMBERS}
              />
              <AppInput
                name="AuthenticationMethod"
                label="Authentication Type"
                type="select"
                options={_enum.AUTHENTICATION_METHODS}
              />
              <AppInput name="ReportingPassword" label="Password" type="text" />
              <div className="col-12 mt-4">
                <button
                  type="submit"
                  className="btn btn-primary w-100 py-3 mb-3"
                >
                  Download
                </button>
                <button
                  type="button"
                  className="btn text-primary w-100 py-3"
                  onClick={(e) => closeTudfModal()}
                >
                  Cancel
                </button>
              </div>
            </AppForm>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default constore(EquifaxReport);
