import React, { useEffect, useRef, useState } from "react";
import MainPagetitle from "../../../layouts/MainPagetitle";
import { Modal } from "react-bootstrap";

const MyApplicationQueries = (props) => {
  const [show, setShow] = useState(false);
  const [queryinfo, setQueryinfo] = useState([]);
  const [fileMetaData, setFileMetaData] = useState(null);
  let BASE_URL = `${endpoints[environment].api_base}/files/download/`;
  const formRef = useRef(null);

  useEffect(() => {
    getQueries();
  }, []);

  const getQueries = () => {
    props.apis
      .apiUnderWriterGetApplicationQuery({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        const groupedConversations = [];
        res.forEach((conversation) => {
          if (!conversation.responseId) {
            // If conversation has no responseId, treat it as parent
            const parentConversation = {
              ...conversation,
              childrenConv: [],
            };
            // Find children conversations
            const childrenConv = res.filter(
              (c) => c.responseId === conversation.conversationId
            );
            parentConversation.childrenConv = childrenConv;
            // Add parent conversation to groupedConversations
            groupedConversations.push(parentConversation);
          }
        });
        console.log(groupedConversations);
        setQueryinfo(groupedConversations);
        // setQueryinfo([
        //   {
        //     status: "status",
        //     messageText: "messageText",
        //     childrenConv: [
        //       {
        //         customerId: 1,
        //         messageText: "messageText",
        //         fileId: "123123",
        //       },
        //       {
        //         customerId: 1,
        //         messageText: "messageText",
        //         fileId: "123123",
        //       },
        //       {
        //         customerId: 1,
        //         messageText: "messageText",
        //         fileId: "123123",
        //       },
        //       {
        //         customerId: 1,
        //         messageText: "messageText",
        //         fileId: "123123",
        //       },
        //       {
        //         customerId: 1,
        //         messageText: "messageText",
        //         fileId: "123123",
        //       },
        //       {
        //         customerId: 1,
        //         messageText: "messageText",
        //         fileId: "123123",
        //       },
        //     ],
        //   },
        //   {
        //     status: "status",
        //     messageText: "messageText",
        //     childrenConv: [
        //       {
        //         customerId: 1,
        //         messageText: "messageText",
        //         fileId: "123123",
        //       },
        //     ],
        //   },
        //   {
        //     status: "status",
        //     messageText: "messageText",
        //     childrenConv: [
        //       {
        //         customerId: 1,
        //         messageText: "messageText",
        //         fileId: "123123",
        //       },
        //     ],
        //   },
        //   {
        //     status: "status",
        //     messageText: "messageText",
        //     childrenConv: [
        //       {
        //         customerId: 1,
        //         messageText: "messageText",
        //         fileId: "123123",
        //       },
        //       {
        //         customerId: 1,
        //         messageText: "messageText",
        //         fileId: "123123",
        //       },
        //       {
        //         customerId: 1,
        //         messageText: "messageText",
        //         fileId: "123123",
        //       },
        //       {
        //         customerId: 1,
        //         messageText: "messageText",
        //         fileId: "123123",
        //       },
        //       {
        //         customerId: 1,
        //         messageText: "messageText",
        //         fileId: "123123",
        //       },
        //       {
        //         customerId: 1,
        //         messageText: "messageText",
        //         fileId: "123123",
        //       },
        //     ],
        //   },
        // ]);
      });
  };

  const handleShow = (e) => {
    setShow(true);
  };

  const handleClose = (e) => {
    setShow(false);
  };

  const handleSubmit = (data, orginalItem) => {
    let payload = {
      ...Object.only(orginalItem, ["applicationId", "category"]),
      ...data,
      fileId: fileMetaData?.fileId,
      filename: fileMetaData?.fileName,
      responseId: orginalItem.conversationId,
    };
    props.apis.apiAddCustomerQueryResponse(payload).then((res) => {
      swal2.fire({
        title: "Response added Successfully !!",
        icon: "success",
        iconColor: "#FF4D4D",
        timer: 4000,
      });
      formRef?.current?.form?.current?.reset();
      setFileMetaData(null);
      getQueries();
    });
  };

  const handleFileSubmit = (e, data) => {
    console.log({ data });
    setFileMetaData(data);
    setShow(false);

    // swal2.fire({
    //   title: "Document Uploaded Successfully !!",
    //   icon: "success",
    //   iconColor: "#FF4D4D",
    //   timer: 4000,
    // });
  };
  return (
    <>
      <MainPagetitle
        mainTitle={`My Application | (Application No.)${props.urlparams.params.id}`}
        back={"Queries"}
        backLink="/app/myapplications"
        pageTitle={"My Application"}
        parentTitle={"Home"}
        pageIcon={imagepaths.greyUserCheckIconActive}
      />

      <div className="container-fluid">
        <div className="card p-4">
          <h2 className="mb-3 fs-4">Queries</h2>
          <div className="row g-4 query_card_container">
            {queryinfo.map((item, index) => (
              <div className="col-12 col-md-6">
                <div className="query_card app_query">
                  <p className="title">Query {index + 1}</p>
                  {item?.status === "ACCEPT" && (
                    <i class="fa-solid fa-circle-check checkmark_icon"></i>
                  )}
                  {item?.status === "REJECT" && (
                    <i class="fa-solid fa-circle-xmark status_icon"></i>
                  )}
                  <div className="contents">
                    <ul>
                      <li className="mb-3">
                        <span>Underwriter Remarks</span>
                        <span>{item?.messageText}</span>
                      </li>
                      {item?.childrenConv.map((child, i) => (
                        <li className="mb-3">
                          <span>
                            {child.customerId ? "Your" : "Underwriter"} Remarks
                          </span>
                          <div className="link_box_container">
                            <div className="link_box">
                              <span>{child.messageText}</span>
                              {child.fileId && (
                                <a href={`${BASE_URL}${child?.fileId}`}>
                                  Download
                                </a>
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {!item.status && (
                    // {true && (
                    <>
                      <hr />
                      <AppForm
                        onSubmit={(e, data) => {
                          e.preventDefault();
                          handleSubmit(data, item);
                        }}
                      >
                        <div className="bottom_footer">
                          <div className="docs_card_container row g-0">
                            <div className="col-12">
                              <div className="remarks_input_box">
                                <AppInput
                                  type="textarea"
                                  label="Remarks"
                                  name="messageText"
                                  required={true}
                                ></AppInput>
                              </div>
                            </div>
                            <div className="nav_container mt-3">
                              <button
                                type="button"
                                className="btn btn-primary-lite me-3"
                                onClick={(...args) => handleShow(...args)}
                              >
                                Upload
                              </button>
                              <button className="btn btn-primary" type="submit">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </AppForm>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={(...args) => handleClose(...args)}
        className="customer_application_modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <AppForm onSubmit={handleFileSubmit}>
            <AppInput
              name="fileId"
              label="Upload"
              type="file"
              fileLabel="Upload pdf"
              foldername="customerDocs"
            ></AppInput>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary-lite"
                onClick={(...args) => handleClose(...args)}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Upload
              </button>
            </Modal.Footer>
          </AppForm>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default constore(MyApplicationQueries);
