import React, { useState, useEffect } from "react";

import MainPagetitle from "../../../layouts/MainPagetitle";
import DisbursementReportSearch from "./DisbursementReportSearch/DisbursementReportSearch";

const DisbursementReport = (props) => {
  const [search, setSearch] = useState({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [rowcount, setRowcount] = useState(0);
  const columns = [
    {
      dataIndex: "customerName",
      title: "Name",
      sorter: (a, b) => a.customerName.length - b.customerName.length,
      render: (text, record) => {
        return <h6 className="text-primary">{record.customerName}</h6>;
      },
    },
    {
      dataIndex: "loanAccountNumber",
      title: "LAN No.",
      render: (text, record) => {
        return (
          <span>
            {record?.applicationLoanAccount?.loanAccountNumber || "-"}
          </span>
        );
      },
    },
    {
      dataIndex: "customerMobileNumber",
      title: "Mobile No.",
      render: (text, record) => {
        return <span>{text || "-"}</span>;
      },
    },
    {
      dataIndex: "sanctionedLoanAmount",
      title: "Sanctioned Loan Amt.",
      render: (text, record) => {
        return (
          <span>
            {record?.applicationLoanAccount?.sanctionedLoanAmount?.cur() || "-"}
          </span>
        );
      },
    },
    {
      dataIndex: "sanctionedLoanStartDate",
      title: "Loan Start Date",
      render: (text, record) => {
        let startDate = record?.applicationLoanAccount?.sanctionedLoanStartDate;
        if (startDate) {
          let date = new Date(startDate).dateToDDMMYYYY(new Date(startDate));
          return date || "-";
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "sanctionedLoanEndDate",
      title: "Loan End Date",
      render: (text, record) => {
        let startDate = record?.applicationLoanAccount?.sanctionedLoanEndDate;
        if (startDate) {
          let date = new Date(startDate).dateToDDMMYYYY(new Date(startDate));
          return date || "-";
        } else {
          return "-";
        }
      },
    },
    {
      dataIndex: "channel",
      title: "Channel",
    },
    {
      dataIndex: "netDisbursementAmt",
      title: "Net Disbursement Amt.",
      render: (text, record) => {
        return (
          <span>
            {record?.applicationLoanAccount?.netDisbursementAmt?.cur() || "-"}
          </span>
        );
      },
    },
    {
      dataIndex: "sanctionedLoanTenure",
      title: "Tenure",
      render: (text, record) => {
        return (
          <span>
            {`${record?.applicationLoanAccount?.sanctionedLoanTenure} Days` ||
              "-"}
          </span>
        );
      },
    },
    {
      dataIndex: "disbursementDate",
      title: "Disbursement date",
      render: (text, record) => {
        let disbursementDate = record?.applicationLoanAccount?.disbursementDate;
        if (disbursementDate) {
          let date = new Date(disbursementDate).dateToDDMMYYYY(
            new Date(disbursementDate)
          );
          return date || "-";
        } else {
          return "-";
        }
      },
    },
  ];

  const onPageChange = (pageData) => {
    console.log({ onPageChange: pageData });
    let payload = {
      page: pageData.current,
      limit: pageData.pageSize || pageData.defaultPageSize || 10,
    };
    getAllDisbursementApplications(payload, false);
  };

  const onSearch = (data, reset) => {
    console.log("onSearch on leads", data, reset);

    let alteredData = {};

    if (data?.start_date?.length > 0) {
      alteredData.start_date = data?.start_date;
    }
    if (data?.end_date?.length > 0) {
      alteredData.end_date = data?.end_date;
    }

    if (Object.keys(data).length === 0) {
      setSearch({
        ...alteredData,
        ...search,
      });
      getAllDisbursementApplications(alteredData, reset);
    } else {
      setSearch({
        ...alteredData,
        ...search,
      });
      getAllDisbursementApplications(alteredData, reset);
    }
  };

  const downloadFile = async () => {
    let payload = {
      ProcessorUserID: "031FP11223",
      ProcessorShortName: "ABCBANK",
      CycleIdentification: "10",
      ReportingPassword: "1234",
      AuthenticationMethod: "L",
      loanAccountNumbers: [...selectedRowKeys],
    };
    console.log({ payload });

    // if (payload?.loanAccountNumbers.length === 0) {
    //   swal2.fire({
    //     title: "Please select any application",
    //     icon: "info",
    //     iconColor: "#FF4D4D",
    //   });
    // } else {
    //   props.apis.apiGetTudfFile(payload).then(async (res) => {
    //     if (res) {
    //       console.log({ res });
    //       let data = await res;
    //       let blob = new Blob([data], {
    //         type: "text/html; charset=utf-8",
    //       });
    //       let link = document.createElement("a");
    //       link.href = URL.createObjectURL(blob);
    //       link.setAttribute("download", `report_${new Date().toDate()}.txt`);
    //       link.click();
    //     }
    //   });
    // }
  };

  // ROW SELECTION
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getAllDisbursementApplications = (params = {}, reset) => {
    let payload = {};
    if (reset) {
      let defaultSearchData = Object.only(search, ["page", "limit"]);
      setSearch(defaultSearchData);
      payload = {
        ...defaultSearchData,
        ...params,
      };
    } else {
      payload = {
        ...search,
        ...params,
      };
    }
    props.apis
      .apiGetAllDisbursementApplications(payload)
      .then(({ data, total }) => {
        setTableData(data);
        setIsFetching(false);
        setRowcount(total);
      });
  };

  useEffect(() => {
    getAllDisbursementApplications();
  }, []);

  props.$setNode({
    props,
  });

  return (
    <>
      <MainPagetitle
        mainTitle="Reports"
        back={"Disbursement"}
        backLink={`/app/disbursementreports`}
        pageTitle={"Disbursement"}
        parentTitle={"Home"}
        pageIcon={imagepaths.reportsIconActive}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption-container">
                  <h4 className="heading mb-0">Disbursement Report</h4>
                  <DisbursementReportSearch
                    onSearch={(...arg) => onSearch(...arg)}
                  />
                </div>
                <div className="action_btn_group text-end mb-4">
                  <button
                    type="button"
                    className="btn btn-primary ms-1 me-5"
                    onClick={(...args) => downloadFile(...args)}
                  >
                    <i className="fa fa-download me-2"></i>
                    Download Excel
                  </button>
                </div>
                <AppTable
                  data={tableData}
                  columns={columns}
                  onChange={(...arg) => onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  editable={false}
                  viewable={false}
                  deletable={false}
                  loading={isFetching}
                  checkbox={true}
                  rowSelection={rowSelection}
                  isCustomRowKey={true}
                  customRowKey={["applicationLoanAccount", "loanAccountNumber"]}
                ></AppTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default constore(DisbursementReport);
