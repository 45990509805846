import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import CibilCard from "./CibilCard";
import moment from "moment";

const Cibil = (props) => {
  var isReadonly = props.urlparams.params.readonly === "true" ? true : false;
  var BASE_URL = `${endpoints[environment].api_base}/files/download/`;

  const [showCibilreport, setShowCibilreport] = useState(false);
  const [cibilData, setCibilData] = useState({});
  const [cibilfields, setCibilfields] = useState([
    {
      name: "customerName",
      label: "Customer Name",
      type: "text",
      readOnly: true,
    },
    {
      name: "mobileNumber",
      label: "Mobile No.",
      type: "text",
      readOnly: true,
    },
    {
      name: "emailAddress",
      label: "Email ID",
      type: "text",
      readOnly: true,
    },
    {
      name: "dob",
      label: "DOB",
      type: "date",
      readOnly: true,
    },
    {
      name: "age",
      label: "Age",
      type: "text",
      readOnly: true,
    },
    {
      name: "gender",
      label: "Gender",
      type: "text",
      readOnly: true,
    },
    {
      name: "pan",
      label: "PAN No.",
      type: "text",
      readOnly: true,
    },
    {
      name: "address",
      label: "Address",
      type: "text",
      readOnly: true,
      customcolspan: 12,
    },
  ]);
  const [obligationAmount, setObligationAmount] = useState(0);
  const [additionalLoanInfo, setAdditionalLoanInfo] = useState([]);
  // ----------------------------------------------------------------

  const calculateObligationAmount = () => {
    mixins.debounce(() => {
      let totalAmount = 0;
      if (additionalLoanInfo?.length > 0) {
        additionalLoanInfo.forEach((loan) => {
          if (loan.factoredInInstallment === "yes") {
            totalAmount = totalAmount + loan.installmentAmt;
          }
        });
      }
      setObligationAmount(totalAmount);
    }, 1000);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    calculateObligationAmount();
  }, [additionalLoanInfo]);

  const fetchCibilDetails = () => {
    props.apis
      .apiGetUnderWriterCibilDetails({
        applicationNumber: props.urlparams.params.id,
      })
      .then((res) => {
        setCibilData(res);
        setShowCibilreport(true);
        fetchAdditionalCibilInfo(res.applicationEquifaxCibilId);
      })
      .catch((error) => {});
  };

  const mergeAddress = (obj) => {
    // Destructure the relevant properties from the object
    const { address1, address2, address3 } = obj;

    // Initialize an array to hold the address parts
    let addressParts = [];

    // Add each address part to the array if it is not null
    if (address1) addressParts.push(address1);
    if (address2) addressParts.push(address2);
    if (address3) addressParts.push(address3);

    // Join the address parts with a comma and return the result
    return addressParts.join(", ");
  };

  const fetchUserDetails = () => {
    props.apis
      .apiGetUnderWriterApplicationDetails({
        applicationNumber: props.urlparams.params.id,
      })
      .then((data) => {
        if (data.isCibilVerified) fetchCibilDetails();

        let fullAddress = mergeAddress(data?.ApplicationAddressInfo?.[0]);

        let editData = {
          ...data.customer,
          customerName: data?.customerName,
          // dob: moment(data?.customer?.dob)?.format("DD-MM-YYYY"),
          age: moment().diff(data?.customer?.dob, "years"),
          address: fullAddress?.toTitleCase(),
          pan: data?.customer?.customerPanCardInfo?.number,
          gender: data?.customer?.gender,
        };
        let fields = cibilfields.map((field) => {
          field.defaultValue = field.defaultValue;
          if (![undefined].includes(editData[field.name])) {
            if (field.type == "date") {
              field.defaultValue = editData[field.name].date();
            } else {
              field.defaultValue = editData[field.name];
            }
          }
          return field;
        });
        setCibilfields(fields);
      })
      .catch((error) => {});
  };

  const fetchAdditionalCibilInfo = (applicationEquifaxCibilId) => {
    props.apis
      .apiUnderWriterGetAdditionalCibilInfo({
        applicationEquifaxCibilId: applicationEquifaxCibilId,
      })
      .then(({ data }) => {
        setAdditionalLoanInfo(data);
        calculateObligationAmount();
      })
      .catch((error) => {});
  };

  // ----------------------------------------------------------------

  return (
    <>
      <div className="card p-4">
        <AppForm>
          {cibilfields.map((field, i) => (
            <div
              className={`col-6 col-md-4 col-lg-${field?.customcolspan || 4}`}
              key={i}
            >
              <AppInput {...field}></AppInput>
            </div>
          ))}
        </AppForm>
        <div className="card-footer border-0 px-md-0">
          <div
            className={Object.className({
              "d-none": !showCibilreport,
            })}
          >
            <div className="action_btn_group text-end">
              <button type="button" className="btn btn-primary-transparent">
                <a
                  href={`${BASE_URL}${cibilData?.cibilFileId}`}
                  className="file_download_btn text-primary"
                >
                  <i className="fa fa-download me-2"></i>
                  Download PDF
                </a>
              </button>
            </div>
            <CibilCard data={cibilData} />
          </div>
        </div>
      </div>

      <div
        className={Object.className({
          "card p-4": true,
          "d-none": !showCibilreport,
        })}
      >
        <div className="form_group_card px-0">
          <div className="d-flex align-items-start justify-content-between">
            <h2 className="mb-3 theme_text_sm">
              Additional Information To Be Filled
            </h2>
          </div>
          {additionalLoanInfo?.map((item, i) => (
            <div className="row" key={i}>
              <div className="col-12 col-md-3 col-lg-2">
                <AppInput
                  name={`${i}.loanType`}
                  label="Type of Loan"
                  type="select"
                  selectNone={isReadonly}
                  defaultValue={item?.loanType}
                  options={_enum.MOKE_LOAN_TYPES}
                  outerclass={Object.className({
                    "col-xl-6 mb-3 w-100 d-flex flex-column pe-none":
                      isReadonly,
                  })}
                  disabled
                ></AppInput>
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <AppInput
                  name={`${i}.loanAmt`}
                  label="Loan Amt."
                  defaultValue={item?.loanAmt}
                  type="number"
                  outerclass={Object.className({
                    "col-xl-6 mb-3 w-100 d-flex flex-column pe-none":
                      isReadonly,
                  })}
                  disabled
                ></AppInput>
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <AppInput
                  name={`${i}.outstandingAmt`}
                  label="Outstanding"
                  defaultValue={item?.outstandingAmt}
                  type="number"
                  outerclass={Object.className({
                    "col-xl-6 mb-3 w-100 d-flex flex-column pe-none":
                      isReadonly,
                  })}
                  disabled
                ></AppInput>
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <AppInput
                  name={`${i}.installmentAmt`}
                  label="EMI Amt."
                  type="number"
                  defaultValue={item?.installmentAmt}
                  // onChange={(...args) => handleEmiAmount(...args, i)}
                  outerclass={Object.className({
                    "col-xl-6 mb-3 w-100 d-flex flex-column pe-none":
                      isReadonly,
                  })}
                  disabled
                ></AppInput>
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <AppInput
                  name={`${i}.factoredInInstallment`}
                  label=" EMI To Be Factored"
                  type="select"
                  selectNone={isReadonly}
                  defaultValue={item?.factoredInInstallment}
                  options={_enum.YES_OR_NO}
                  ō
                  outerclass={Object.className({
                    "col-xl-6 mb-3 w-100 d-flex flex-column pe-none":
                      isReadonly,
                  })}
                  disabled
                ></AppInput>
              </div>
            </div>
          ))}
        </div>
        <div className="obligation_banner">
          <p className="">
            Total Monthly Obligation : Rs. <span>{obligationAmount}</span>
          </p>
        </div>
      </div>
      {isReadonly && (
        <div className="nav_container mt-5">
          <button
            type="button"
            onClick={(...args) => props.changeTab(...args, "bsainfo")}
            className="btn btn-primary-lite me-3"
          >
            Back
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(...args) => props.changeTab(...args, "queries")}
          >
            Next
          </button>
        </div>
      )}
    </>
  );
};

export default constore(Cibil);
