import React, { useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import MainPagetitle from "../../../layouts/MainPagetitle";
import axios from "axios";

const BankStatementAnalyzer = (props) => {
  var UNDERWRITER_ADD_BSA_INFO_URL = `${endpoints[environment].api_base}/loan-bgv/bsa/solo/upload`;
  var UNDERWRITER_GET_BSA_SUMMARY = `${endpoints[environment].api_base}/loan-bgv/bsa/solo/summary`;
  var UNDERWRITER_GET_BSA_ENQUIRIES_URL = `${endpoints[environment].api_base}/loan-bgv/bsa/solo/bsa/solo`;
  const bsaForm = useRef(null);
  const [passcheck, setPasscheck] = useState(false);

  const handlePasswordCheck = (e) => {
    let value = e.target.checked;
    setPasscheck(value);
  };

  // ----------------------------------------------------------------
  // Recursive function to call the second GET API
  var iterationcount = 0;
  const checkStatus = async (id) => {
    let globalLoader = document.getElementById("globalLoader");
    let checkinput = document.querySelector("#passcheck");

    const resolve = () => {
      globalLoader.classList.add("d-none");
      checkinput.checked ? checkinput.click() : null;
      bsaForm.current.form.current.reset();
    };

    try {
      let url = `${UNDERWRITER_GET_BSA_SUMMARY}/${id}`;
      let authToken = props?.$store?.auth;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const status = response.data.status;

      if (status !== "COMPLETED") {
        // maximum number of retry attempts for second API call
        if (iterationcount >= 20) {
          swal2.fire({
            title: "BSA taking too long to respond",
            text: "Please try again later",
            icon: "warning",
            iconColor: "#FF4D4D",
          });
          resolve();
          return;
        } else {
          setTimeout(() => {
            checkStatus(id);
            iterationcount++;
          }, 2000); // Call checkStatus again after 2 seconds
        }
      } else {
        resolve();
        let filelink = response?.data?.xlsx_signed_url;
        let fname = response?.data?.file_name;

        // Create a PDF download link
        let atag = document.createElement("a");
        atag.href = filelink || "";
        atag.download = fname;
        document.body.appendChild(atag);
        atag.click();
        document.body.removeChild(atag);

        swal2.fire({
          title: "Report excel is generated successfully.",
          icon: "success",
          iconColor: "#FF4D4D",
          timer: 4000,
        });
      }
    } catch (error) {
      // console.error("Error:", error);
      resolve();
      let message =
        error.response?.data?.issues?.error ||
        error.response?.data?.issues?.message ||
        error.response?.data?.issues?.data.error ||
        error.response?.data?.message ||
        error.response?.data?.msg;
      swal2.fire({
        icon: "error",
        iconColor: "#FF4D4D",
        title: message,
      });
      if (error?.response?.status == 401) {
        console.log("UNAUTHORIZED");
        swal2.fire({
          title: "Oops!",
          text: "Session has expired",
          icon: "error",
          iconColor: "#FF4D4D",
        });
        props?.apis?.applogout();
      }
    }
  };

  const handleBsaSubmit = async (e, data) => {
    let globalLoader = document.getElementById("globalLoader");
    let checkinput = document.querySelector("#passcheck");

    globalLoader.classList.remove("d-none");
    let payload = {
      file: data.file,
    };
    let urldata = {
      ...Object.only(data, ["bank_name", "statement_file_password"]),
    };

    let url = `${UNDERWRITER_ADD_BSA_INFO_URL}${Object.encodeuri(urldata)}`;
    let authToken = props?.$store?.auth;
    // console.log({ data, payload, urldata, url });

    const resolve = () => {
      globalLoader.classList.add("d-none");
      checkinput.checked ? checkinput.click() : null;
      bsaForm.current.form.current.reset();
    };

    try {
      // First POST API call to submit the form
      const response = await axios.post(url, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      });
      const id = response?.data?.application_id;

      // Call the checkStatus function initially
      checkStatus(id);
    } catch (error) {
      resolve();
      let message =
        error.response?.data?.issues?.error ||
        error.response?.data?.issues?.message ||
        error.response?.data?.issues?.data.error ||
        error.response?.data?.message ||
        error.response?.data?.msg;
      swal2.fire({
        icon: "error",
        iconColor: "#FF4D4D",
        title: message,
      });
    }
  };
  props.$setNode({
    props,
    bsaForm,
  });
  return (
    <>
      <MainPagetitle
        mainTitle="Bank Statement Analyzer"
        pageTitle="Bank Statement Analyzer"
        parentTitle={"Home"}
        pageIcon={imagepaths.applicationCheckActiveImg}
      />
      <div className="container-fluid">
        <div className="card p-4 bsa_card">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6 col-xl-8">
              <h2 className="title mb-3">What is Bank Statement Analyzer?</h2>
              <p className="">
                We at Impetus AI Solutions, are focused on providing analytical
                services to financial institutes. We provide robust technology
                driven services, and with our experience with the financial
                industry, we understand how difficult it is for financial
                institutions to perform these credit checks. We are offering a
                state of the art Bank Statement Analyzer that uses machine
                learning and artificial intelligence to scan your applicant's
                bank statements, and performs a series of checks, giving results
                on whether the statement is trustworthy or not.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-4 bsa_form_column">
              <AppForm
                ref={bsaForm}
                onSubmit={(...args) => handleBsaSubmit(...args)}
              >
                <div className="col-12">
                  <AppInput
                    name="bank_name"
                    label="Bank Name"
                    type="select"
                    options={_enum.BSA_BANKS}
                    required={true}
                  ></AppInput>
                </div>
                <div className="col-12">
                  <AppInput
                    name="file"
                    label="Bank Statement"
                    type="defaultfile"
                    foldername="bsaDocs"
                    required={true}
                  ></AppInput>
                </div>

                <Form.Check
                  type="checkbox"
                  id={`passcheck`}
                  label="Password protected"
                  className="ms-3"
                  onChange={(...args) => handlePasswordCheck(...args)}
                />
                {passcheck ? (
                  <div className="col-12">
                    <AppInput
                      type="text"
                      label="Password"
                      name="statement_file_password"
                    ></AppInput>
                  </div>
                ) : null}
                <div className="col-12 text-end">
                  <Button type="submit" variant="primary">
                    Upload
                  </Button>
                </div>
              </AppForm>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default constore(BankStatementAnalyzer);
