import React from "react";

const KycCard = (props) => {
  var BASE_URL = `${endpoints[environment].api_base}/files/download/`;
  const { data } = props;
  return (
    <div className="card profile_card">
      <div className="card-header p-0 border-0">
        <div className="d-flex align-items-center mb-3">
          <img
            src={imagepaths.userSearchIcon}
            alt="userSearchIcon"
            className="card_icon"
          />
          <h5 className="title ms-2 mb-0">KYC Details</h5>
        </div>
      </div>
      <div className="card-body p-0">
        <div className="content_box_container row g-3">
          <div className="col-12 col-lg-6">
            <ul className="content_box mb-3">
              <li>
                <span className="label">Aadhar No:</span>
                <span>
                  {(data?.customerAadharCardInfo &&
                    data?.customerAadharCardInfo?.uid) ||
                    "-"}
                </span>
              </li>
              {/* <li>
                <span className="label">Residence Status:</span>
                <span>
                  {(data?.CustomerAddressInfo &&
                    data?.CustomerAddressInfo[0]?.residenceStatus) ||
                    "-"}
                </span>
              </li> */}
              <li>
                <span className="label">PIN Code:</span>
                <span>
                  {(data?.customerAadharCardInfo &&
                    data?.customerAadharCardInfo?.pincode) ||
                    "-"}
                </span>
              </li>
              <li>
                <span className="label">City :</span>
                <span>
                  {(data?.customerAadharCardInfo &&
                    data?.customerAadharCardInfo?.city) ||
                    "-"}
                </span>
              </li>
            </ul>
          </div>

          <div className="col-12 col-lg-6">
            <ul className="content_box mb-3">
              <li>
                <span className="label">PAN No:</span>
                <span>
                  {(data?.customerPanCardInfo &&
                    data?.customerPanCardInfo?.number) ||
                    "-"}
                </span>
              </li>
              <li>
                <span className="label">Aadhar Address :</span>
                <span>
                  {(data?.customerAadharCardInfo &&
                    data?.customerAadharCardInfo?.address) ||
                    "-"}
                </span>
              </li>
              <li>
                <span className="label">State:</span>
                <span>
                  {(data?.customerAadharCardInfo &&
                    data?.customerAadharCardInfo?.state) ||
                    "-"}
                </span>
              </li>
              {/* <li>
                <span className="label">Document Proof :</span>
                <div className="d-flex flex-wrap">
                  <span>
                    {(data?.CustomerAddressInfo &&
                      _enum.DOC_TYPES_MAP[
                        data?.CustomerAddressInfo[0]?.docType
                      ]) ||
                      "-"}
                  </span>
                  <span className="ms-2">
                    {data?.CustomerAddressInfo && (
                      <a
                        href={`${BASE_URL}${data?.CustomerAddressInfo[0]?.fileId}`}
                        className="download_link"
                      >
                        Download
                      </a>
                    )}
                  </span>
                </div>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default constore(KycCard);
